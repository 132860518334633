<template>
    <section class="custom-scroll overflow-auto" style="height:calc(100vh - 300px)">
        <div class="row mx-3 br-12">
            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 px-0 my-4">
                <div class="row mx-0">
                    <div class="col-9 bg-white">
                        <div class="row mx-0">
                            <div class="col-auto">
                                <p class="text-general mx-3 mt-3 f-18">{{ $config.vendedor }} comisionistas</p>
                            </div>
                        </div>
                        <div class="row mx-0">
                            <div class="col">
                                <cargando v-if="cargandoComision" />
                                <echart v-if="showLeecherosComisiones" :options="leecherosComisiones" width="100%" />
                                <img v-if="!showLeecherosComisiones" src="/img/otros/no_datos.svg" class="d-middle" style="width:100%; height:360px" />
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div class="col-auto" />
                            <div class="col-auto" />
                            <div class="col-auto" />
                            <div class="col-auto" />
                            <div class="col mx-3">
                                <div class="class-button d-middle-center mr-2 ml-2 cr-pointer text-general" @click="cambiarCondicion">
                                    Cambiar la condición de todos los {{ $config.vendedor }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-2 mx-1 bg-white">
                        <div class="row mx-0 mt-3">
                            <p class="text-general f-15">{{ $config.vendedor }} con comisión</p>
                            <p class="text-general f-600 f-40">{{ conComisionTienda }}</p>
                        </div>
                        <hr />
                        <div class="row mx-0 mt-3">
                            <p class="text-general f-15">{{ $config.vendedor }} sin comisión</p>
                            <p class="text-general f-600 f-40">
                                {{ sinComisionTienda }}
                                <el-tooltip content="A estos vendedores no se les ha asignado una condición de comisión" placement="bottom" effect="light">
                                    <i class="icon-help-circle-outline f-25" />
                                </el-tooltip>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 px-0 my-4">
                <div class="row mx-0">
                    <div class="col-9 bg-white">
                        <div class="row mx-0">
                            <div class="col-auto">
                                <p class="text-general mx-3 mt-3 f-18">{{ $config.vendedor }} con descuento de lista</p>
                            </div>
                        </div>
                        <div class="row mx-0">
                            <div class="col">
                                <cargando v-if="cargandoDescuento" />
                                <echart v-if="showLeecherosDescuentos" :options="leecherosDescuentos" width="100%" />
                                <img v-if="!showLeecherosDescuentos" src="/img/otros/no_datos.svg" class="d-middle" style="width:100%; height:360px" />
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div class="col-auto" />
                            <div class="col-auto" />
                            <div class="col-auto" />
                            <div class="col-auto" />
                            <div class="col mx-3">
                                <div class="class-button d-middle-center mr-2 ml-2 cr-pointer text-general" @click="cambiarDescuento">
                                    Cambiar el beneficio de descuento
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-2 mx-1 bg-white">
                        <div class="row mx-0 mt-3">
                            <p class="text-general f-15">{{ $config.vendedor }} con descuento de lista</p>
                            <p class="text-general f-600 f-40">{{ conDescuentoTienda }}</p>
                        </div>
                        <hr />
                        <div class="row mx-0 mt-3">
                            <p class="text-general f-15">{{ $config.vendedor }} sin descuento de lista</p>
                            <p class="text-general f-600 f-40">
                                {{ sinDescuentoTienda }}
                                <el-tooltip content="A estos vendedores no se les ha asignado el beneficio de descuento de lista" placement="bottom" effect="light">
                                    <i class="icon-help-circle-outline f-25" /> 
                                </el-tooltip>
                            </p>
                        </div>
                        <hr />
                        <div class="row mx-0 mt-3">
                            <p class="text-general f-15">{{ $config.cliente }} con descuento de lista</p>
                            <p class="text-general f-600 f-40">{{ conDescuentoCliente }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal-cambiar-condicion ref="refModalCambiarCondicion" @update="reloadGraficaComisiones" />
        <modal-cambiar-descuento ref="refModalCambiarDescuento" @update="reloadGraficaDescuentos" />
    </section>
</template>
<script>
import Vue from 'vue'
import echarts from 'echarts'
import Service from "~/services/alianzas_leecheros";

Vue.component('echarts',echarts);

export default {
    components: {
        modalCambiarCondicion: () => import('./partials/modalCambiarCondicion'),
        modalCambiarDescuento: () => import('./partials/modalCambiarDescuento'),
    },

    data(){
        return {
            cargandoComision: false,
            cargandoDescuento: false,
            showLeecherosComisiones: false,
            showLeecherosDescuentos: false,
            conComisionTienda: 0,
            sinComisionTienda: 0,
            conDescuentoTienda: 0,
            sinDescuentoTienda: 0,
            conDescuentoCliente: 0,
            // Data gráfica comisiones
            leecherosComisiones: {
                tooltip: {
                    trigger: 'item',
                    formatter: (params) => {
                        return `${params.name} <br />
                        ${params.marker} ${params.value} ${this.$config.vendedor} <br />
                        ${params.percent}%
                        `
                    },
                },
                series: [
                    {
                        type: 'pie',
                        radius: ['50%', '70%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center'
                        },
                        labelLine: {
                            show: false
                        },
                        data: [],
                    }
                ]
            },
            // Data gráfica descuentos
            leecherosDescuentos: {
                tooltip: {
                    trigger: 'item',
                    formatter: (params) => {
                        return `${params.name} <br />
                        ${params.marker} ${params.value} ${this.$config.vendedor} <br />
                        ${params.percent}%
                        `
                    },
                },
                series: [
                    {
                        type: 'pie',
                        radius: ['50%', '70%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center'
                        },
                        labelLine: {
                            show: false
                        },
                        data: [],
                    }
                ]
            },
        }
    },

    computed: {
        idCedis(){
            return Number(this.$route.params.id_cedis);
        }
    },

    mounted(){
        this.getGraficaDescuentos();
        this.getGraficaComisiones();
    },

    methods: {
        async getGraficaDescuentos(){
            try {
                this.cargandoDescuento = true;

                const {data} = await Service.getGraficaDescuentos(this.idCedis);
                this.conDescuentoTienda = data.conDescuentoTienda;
                this.sinDescuentoTienda = data.sinDescuentoTienda;
                this.conDescuentoCliente = data.conDescuentoCliente;
                this.armarGraficaDescuentos(data.grafica);
            } catch(e){
                this.error_catch(e);
            }
        },

        async getGraficaComisiones(){
            try {
                this.cargandoComision = true;

                const {data} = await Service.getGraficaDescuentosComisiones(this.idCedis);
                this.conComisionTienda = data.conComisionTienda;
                this.sinComisionTienda = data.sinComisionTienda;
                this.armarGraficaComisiones(data.grafica);
            } catch(e){
                this.error_catch(e);
            }
        },

        async armarGraficaDescuentos(data){
            const contador = data.length;
            if(contador === 0)return this.cargandoDescuento = false;

            let insertData = [];
            for(let i = 0; i < contador; i++){
                insertData.push({
                    value: data[i].total,
                    name: data[i].nombre,
                })
            }
            this.leecherosDescuentos.series[0].data = insertData;
            this.showLeecherosDescuentos = true;
            this.cargandoDescuento = false;
        },

        async armarGraficaComisiones(data){
            const contador = data.length;
            if(contador === 0)return this.cargandoComision = false;

            let insertData = [];
            for(let i = 0; i < contador; i++){
                insertData.push({
                    value: data[i].total,
                    name: data[i].condicion,
                })
            }
            this.leecherosComisiones.series[0].data = insertData;
            this.showLeecherosComisiones = true;
            this.cargandoComision = false;
        },

        async reloadGraficaDescuentos(){
            this.conDescuentoTienda = 0;
            this.sinDescuentoTienda = 0;
            this.conDescuentoCliente = 0;
            this.leecherosDescuentos.series[0].data = [];
            this.showLeecherosDescuentos = false;
            this.getGraficaDescuentos();
        },

        async reloadGraficaComisiones(){
            this.conComisionTienda = 0;
            this.sinComisionTienda = 0;
            this.leecherosComisiones.series[0].data = [];
            this.showLeecherosComisiones = false;
            this.getGraficaComisiones();
        },

        cambiarDescuento(){
            this.$refs.refModalCambiarDescuento.toggle();
        },

        cambiarCondicion(){
            this.$refs.refModalCambiarCondicion.toggle();
        },
    }
}
</script>
<style scoped>
.class-button {
    width: 320px;
    height: 32px;
    font-size: 14px;
    border-radius: 8px;
    background-color: #FFFFFF;
	border: 1px solid #DBDBDB;
}
</style>