<template>
    <section class="custom-scroll overflow-auto pt-3" style="height:calc(100vh - 180px)">
        <div class="row mx-0 align-items-center mb-3">
            <div class="col d-middle px-0">
                <div class="d-flex calendar-move br-8">
                    <div class="h-100 w-50 bg-general3 d-middle-center br-l-8 text-white cr-pointer" @click="restarRangoFechas">
                        <i class="icon-angle-left" />
                    </div>
                    <div class="h-100 w-50 bg-general3 d-middle-center br-r-8 text-white cr-pointer" @click="sumarRangoFechas">
                        <i class="icon-angle-right" />
                    </div>
                </div>
                <div class="box-day ml-2 cr-pointer" @click="setHoy">
                    Hoy
                </div>
            </div>
            <div class="col-4 px-0">
                <el-date-picker
                v-model="fecha"
                size="small"
                class="w-100 select-date"
                popper-class="dropdown-class"
                type="daterange"
                format="dd-MM-yyyy"
                value-format="yyyy-MM-dd"
                placeholder="Seleccionar fecha"
                :clearable="false"
                @change="setRangoFechas"
                />
            </div>
            <div class="col d-middle justify-content-end px-0">
                <el-tooltip class="item" effect="light" content="Filtro" placement="bottom">
                    <div class="cant-invent p-1 ml-4" style="color:red;" />
                    <el-popover
                    v-model="visible"
                    placement="bottom"
                    width="322"
                    popper-class="p-0 none-border-popover"
                    trigger="click"
                    >
                        <div class="row mx-0 f-600 pl-2 text-white bg-general3 align-items-center" style="height:56px">
                            <i class="icon-cancel f-18 cr-pointer" @click="cerrarFiltro" />
                            <span class="f-17 ml-3">
                                Filtro
                            </span>
                        </div>
                        <div class="pt-4 px-4" style="min-height:131px;">
                            <div class="row mx-0 align-item-center">
                                <el-checkbox v-model="filtros.pendiente" class="check-dark">
                                    <small class="text-general">
                                        Ordenes Pendientes
                                    </small>
                                </el-checkbox>
                            </div>
                            <div class="row mx-0 align-item-center mt-3">
                                <el-checkbox v-model="filtros.confirmadas" class="check-dark">
                                    <small class="text-general">
                                        Ordenes confirmadas completas
                                    </small>
                                </el-checkbox>
                            </div>
                            <div class="row mx-0 align-item-center mt-3">
                                <el-checkbox v-model="filtros.incompletas" class="check-dark">
                                    <small class="text-general">
                                        Ordenes confirmadas incompletas
                                    </small>
                                </el-checkbox>
                            </div>
                        </div>
                        <div class="end-filter row mx-0 align-items-center justify-center" style="height:56px;">
                            <div class="col-5 px-2">
                                <div class="btn-clean cr-pointer" @click="limpiarFiltro">
                                    Limpiar
                                </div>
                            </div>
                            <div class="col-5 px-2">
                                <div class="btn-apply cr-pointer" @click="filtrar">
                                    Filtrar
                                </div>
                            </div>
                        </div>
                        <div slot="reference" class="btn-action border cr-pointer mr-2 d-middle-center">
                            <el-badge v-if="icon_filtro < 3" is-dot class="item">
                                <div class="btn-action border cr-pointer d-middle-center">
                                    <i class="icon-filter-outline f-26" />
                                </div>
                            </el-badge>
                            <i v-else class="icon-filter-outline f-26" />
                        </div>
                    </el-popover>
                </el-tooltip>
                <div class="add-oc d-middle-center mr-2 ml-2 cr-pointer" @click="anadirCompra()">
                    Añadir Oc
                </div>
                <div class="d-flex calendar-move border br-8 mr-2">
                    <el-tooltip placement="bottom" content="Ver como calendario" effect="light">
                        <router-link
                        :to="{name: 'admin.cedis.ordenes-compra.calendario'}"
                        :class="$route.name == 'admin.cedis.ordenes-compra.calendario' ? 'bg-general3 text-white' : 'bg-white text-general'"
                        class="h-100 w-50 border-right d-middle-center br-l-8"
                        >
                            <i class="icon-calendar-outline f-18" />
                        </router-link>
                    </el-tooltip>
                    <el-tooltip placement="bottom" content="Ver como lista" effect="light">
                        <router-link
                        :to="{name: 'admin.cedis.ordenes-compra.lista'}"
                        :class="$route.name == 'admin.cedis.ordenes-compra.lista' ? 'bg-general3 text-white' : 'bg-white text-general'"
                        class="h-100 w-50 d-middle-center br-r-8"
                        >
                            <i class="icon-menu f-18" />
                        </router-link>
                    </el-tooltip>
                </div>
            </div>
        </div>
        <router-view @agregar="anadirCompra" />
        <!-- Partials -->
        <modal-anadir-compra ref="anadirCompra" />
    </section>
</template>

<script>

//import interactionPlugin from '@fullcalendar/interaction'
import Ordenes from "~/services/cedis/cedis_ordenes_compras"
import moment from 'moment'

import {mapGetters} from 'vuex'

export default {

    components: {
        modalAnadirCompra: () => import('./partials/modalAnadirCompra'),
    },
    data(){
        return {
            visible: false,
            fechas: [],
            fecha: [moment().subtract(1, 'week').toDate(),new Date()],
            fecha_mostrar: '',
            icon_filtro: 3,
        }
    },
    computed:{
        ...mapGetters({
            fecha_inicial: `cedis/ordenes_compras/fecha_inicial`,
            fecha_inicial_filtro: 'cedis/ordenes_compras/fecha_inicial_filtro',
            fecha_final_filtro: 'cedis/ordenes_compras/fecha_final_filtro',
            filtros: `cedis/ordenes_compras/filtros`,
            detectarfiltro: `cedis/ordenes_compras/detectarfiltro`,
            info_cedis:'cedis/info_cedis',

            rangoFechas: `cedis/ordenes_compras/rangoFechas`,

        }),
    },
    watch: {
        detectarfiltro(){
            this.detectorCambiosFiltro();
        },
    },
    created(){
        //this.detectarFechaInicial();
        this.setRangoFechas(this.fecha)
    },
    methods: {
        setRangoFechas(dia = null){

            const rango = []

            rango.push(dia[0], dia[1])
            // if(dia){
            //     inicio = moment(dia).startOf('week');
            //     fin = moment(dia).endOf('week');
            // }else{
            //     inicio = moment().startOf('week');
            //     fin = moment().endOf('week');
            // }


            this.$store.commit('cedis/ordenes_compras/setRangoFechas', rango);

            // this.formatoFecha(inicio, fin)
        },
        async detectarFechaInicial(){
            try {
                let params = {
                    fecha: this.fecha
                }
                const {data} = await Ordenes.detectarFechas(params);
                this.fechas = data.fechas;
                this.$store.commit('cedis/ordenes_compras/fecha_inicial_filtro', data.fechas.fecha_inicial);
                this.$store.commit('cedis/ordenes_compras/fecha_final_filtro', data.fechas.fecha_final);
                this.$store.commit('cedis/ordenes_compras/set_fecha_inicial', this.fecha);
                this.formatoFecha();
            } catch(e){
                this.error_catch(e)
            }
        },
        formatoFecha(inicio,fin){
            this.fecha_mostrar = '';
            this.fecha_mostrar = `${moment(inicio).format('DD MMMM')} - ${moment(fin).format('DD MMMM')}`;
        },
        setHoy(){
            const fecha = moment()
            this.setRangoFechas(fecha);
        },
        restarRangoFechas(){
            const fecha = moment(this.rangoFechas[0]).subtract(7, 'days')
            this.setRangoFechas(fecha);
        },
        sumarRangoFechas(){
            const fecha = moment(this.rangoFechas[1]).add(7, 'days')
            this.setRangoFechas(fecha);
        },
        openFiltro(){
            this.$refs.modalFiltro.toggle();
        },
        filtrar(){
            if (this.detectarfiltro){
                this.$store.commit('cedis/ordenes_compras/set_detectar_filtro', false);
            } else {
                this.$store.commit('cedis/ordenes_compras/set_detectar_filtro', true);
            }
            this.$store.commit('cedis/ordenes_compras/set_filtros', this.filtros);
            this.visible = false;
        },
        limpiarFiltro(){
            this.filtros.pendiente = false;
            this.filtros.confirmadas = false;
            this.filtros.incompletas = false;
        },
        cerrarFiltro(){
            this.visible = false;
        },
        detectorCambiosFiltro(){
            this.icon_filtro = 0;
            if (this.filtros.confirmadas === true){
                this.icon_filtro = this.icon_filtro + 1;
            }
            if (this.filtros.incompletas === true){
                this.icon_filtro = this.icon_filtro + 1;
            }
            if (this.filtros.pendiente === true){
                this.icon_filtro = this.icon_filtro + 1;
            }
        },
        anadirCompra(fecha = null){
            this.$refs.anadirCompra.toggle(fecha);
        }
    }
}
</script>
<style lang="scss" scoped>
.calendar-move{
    width: 64px;
    height: 32px;
}
.box-day{
    width: 58px;
    height: 32px;
    background-color: #707070;
    color: #FFFFFF;
    font-size: 17px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.add-oc{
    width: 105px;
    height: 32px;
    font-size: 14px;
    color: #FFFFFF;
    border-radius: 8px;
    background-color: var(--color-general) !important;
}
.end-filter{
    .btn-clean{
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #DBDBDB;
        background-color: #FFFFFF;
        color: #000000;
        border-radius: 8px;
        height: 32px;
    }

    .btn-apply{
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #DBDBDB;
        background-color: #FF1720;
        color: #FFFFFF;
        border-radius: 8px;
        height: 32px;
    }
}
</style>
