<template>
    <section class="pt-3">
        <div
        class="custom-scroll position-relative overflow-auto"
        style="height: calc(100vh - 135px)"
        >
            <cargando v-if="cargando" />
            <div class="row mx-0">
                <div class="col bg-white py-3 d-flex br-8">
                    <div class="col text-center border-right">
                        <p class="f-30 text-general f-600">
                            {{ agregarSeparadoresNumero(info_cedis.productos) }}
                        </p>
                        <div class="row mx-0 align-items-center justify-center text-general f-15 f-600">
                            <i class="icon-package-variant-closed f-22" />
                            Productos
                        </div>
                    </div>
                    <div class="col border-right">
                        <p class="text-general f-30 f-600 text-center">
                            {{ agregarSeparadoresNumero(info_cedis.unidades) }}
                        </p>
                        <div class="row mx-0 text-general align-items-center justify-center f-600">
                            <i class="icon-format-list-checks f-22" />
                            <span>
                                unidades
                            </span>
                        </div>
                    </div>
                    <div class="col">
                        <el-tooltip
                        placement="bottom"
                        content="Valor de productos en inventario"
                        effect="light"
                        >
                            <p class="text-center text-general f-600 f-30">
                                {{ separadorNumero(info_cedis.valores) }}
                                {{ moneda.sigla }}
                            </p>
                        </el-tooltip>
                        <div class="row mx-0 justify-center align-items-center text-general f-600 f-14">
                            <i class="icon-cash f-14 mr-2" />
                            Valor del stock
                        </div>
                    </div>
                </div>
                <div class="col-2">
                    <div v-if="$can('botones_cedis_gestionar_cedis')" class="col-auto px-1">
                        <el-tooltip placement="bottom" content="Crear Cedis" effect="light">
                            <div
                            class="btn-general d-middle-center br-12 text-white p-1 cr-pointer text-center"
                            style="height:44px;"
                            @click="CrearCedis()"
                            >
                                Crear Cedis
                            </div>
                        </el-tooltip>
                    </div>
                    <div class="col-auto ml-auto px-1">
                        <el-tooltip
                        placement="bottom"
                        content="Ubicacion del Cedis"
                        effect="light"
                        >
                            <div
                            class="bg-white mt-2 border br-12 d-middle-center text-general p-1 cr-pointer"
                            style="height:44px;"
                            @click="mapaCedis()"
                            >
                                <i class="icon-map-marker-outline f-24" />
                                Ver mapa
                            </div>
                        </el-tooltip>
                    </div>
                </div>
            </div>
            <div class="row mx-0 mt-4">
                <div class="col pl-0">
                    <div class="row mx-0 bg-white py-3" style="border-radius:8px 8px 0px 0px;">
                        <div class="col-auto f-22 text-general f-18 f-500">
                            Lista de Cedis
                        </div>
                        <div class="col-3 ml-auto">
                            <el-input
                            v-model="busqueda"
                            class="w-100 br-20"
                            placeholder="Buscar Cedis"
                            />
                        </div>
                    </div>
                    <div class="row mx-0 py-3">
                        <div class="col-2 d-middle-center text-center f-14 cr-pointer text-general f-600" @click="ordenar(1, 'nombre', 'asc')">
                            <div class="position-relative mx-1">
                                <i class="icon-swap-vertical-bold f-18 position-absolute mx-1 text-gris" style="left:-31px;top:-14px;" />
                                <div class="position-relative" :class="containerClass(1)">
                                    <i class="f-18 position-absolute" :class="iconFiltro(1)" style="" />
                                </div>
                            </div>
                            Cedis
                        </div>
                        <div class="col-3 d-middle-center text-center f-14 cr-pointer text-general f-600" @click="ordenar(2, 'nombre_ciudad', 'asc')">
                            <div class="position-relative mx-1">
                                <i class="icon-swap-vertical-bold f-18 position-absolute mx-1 text-gris" style="left:-31px;top:-14px;" />
                                <div class="position-relative" :class="containerClass(2)">
                                    <i class="f-18 position-absolute" :class="iconFiltro(2)" style="" />
                                </div>
                            </div>
                            Ciudad
                        </div>
                        <div class="col-1 d-middle-center text-center f-14 cr-pointer text-general f-600" @click="ordenar(3, 'cant_leecheros_activos', 'asc')">
                            <div class="position-relative mx-1">
                                <i class="icon-swap-vertical-bold f-18 position-absolute mx-1 text-gris" style="left:-31px;top:-14px;" />
                                <div class="position-relative" :class="containerClass(3)">
                                    <i class="f-18 position-absolute" :class="iconFiltro(3)" style="" />
                                </div>
                            </div>
                            {{$config.vendedor}} activos
                        </div>
                        <div class="col-1 d-middle-center text-center f-14 cr-pointer text-general f-600" @click="ordenar(4, 'cant_leecheros_inactivos', 'asc')">
                            <div class="position-relative mx-1">
                                <i class="icon-swap-vertical-bold f-18 position-absolute mx-1 text-gris" style="left:-31px;top:-14px;" />
                                <div class="position-relative" :class="containerClass(4)">
                                    <i class="f-18 position-absolute" :class="iconFiltro(4)" style="" />
                                </div>
                            </div>
                            {{$config.vendedor}} inactivos
                        </div>
                        <div class="col d-middle-center text-center f-14 cr-pointer text-general f-600" @click="ordenar(5, 'cant_clientes', 'asc')">
                            <div class="position-relative mx-1">
                                <i class="icon-swap-vertical-bold f-18 position-absolute mx-1 text-gris" style="left:-31px;top:-14px;" />
                                <div class="position-relative" :class="containerClass(5)">
                                    <i class="f-18 position-absolute" :class="iconFiltro(5)" style="" />
                                </div>
                            </div>
                            {{$config.cliente + 's'}}
                        </div>
                        <div class="col d-middle-center text-center f-14 cr-pointer text-general f-600" @click="ordenar(6, 'productos')">
                            <div class="position-relative mx-1">
                                <i class="icon-swap-vertical-bold f-18 position-absolute mx-1 text-gris" style="left:-31px;top:-14px;" />
                                <div class="position-relative" :class="containerClass(6)">
                                    <i class="f-18 position-absolute" :class="iconFiltro(6)" style="" />
                                </div>
                            </div>
                            Productos
                        </div>
                        <div class="col d-middle-center text-center f-14  cr-pointer text-general f-600" @click="ordenar(7, 'unidades')">
                            <div class="position-relative mx-1">
                                <i class="icon-swap-vertical-bold f-18 position-absolute mx-1 text-gris" style="left:-31px;top:-14px;" />
                                <div class="position-relative" :class="containerClass(7)">
                                    <i class="f-18 position-absolute" :class="iconFiltro(7)" style="" />
                                </div>
                            </div>
                            Unidades
                        </div>
                        <div class="col d-middle-center text-center f-14  cr-pointer text-general f-600" @click="ordenar(8, 'valor')">
                            <div class="position-relative mx-1">
                                <i class="icon-swap-vertical-bold f-18 position-absolute mx-1 text-gris" style="left:-31px;top:-14px;" />
                                <div class="position-relative" :class="containerClass(8)">
                                    <i class="f-18 position-absolute" :class="iconFiltro(8)" style="" />
                                </div>
                            </div>
                            Valor del stock
                        </div>
                        <div class="col d-middle-center text-center f-14  cr-pointer text-general f-600" @click="ordenar(9, 'calificacion')">
                            <div class="position-relative mx-1">
                                <i class="icon-swap-vertical-bold f-18 position-absolute mx-1 text-gris" style="left:-31px;top:-14px;" />
                                <div :class="containerClass(9)">
                                    <i :class="iconFiltro(9)" style="" />
                                </div>
                            </div>
                            Calificación
                        </div>
                    </div>
                    <div
                    v-for="(data, d) in cedis.filter(
                        (c) =>
                            !busqueda ||
                            c.nombre.toLowerCase().includes(busqueda.toLowerCase()) ||
                            c.direccion.toLowerCase().includes(busqueda.toLowerCase())
                    )"
                    :key="d"
                    class="cr-pointer"
                    >
                        <div :class="`row mx-0 align-items-center border-bottom bg-white py-2 ${!data.estado?'disabled-lista':''}`" @click="verCedis(data,data.estado)">
                            <div class="col-2 pl-4 tres-puntos px-0">
                                <img :src="data.imagen" width="54" height="54" class="obj-cover br-5 border" />
                                <span class="text-general tres-puntos f-18 f-600 mx-1">
                                    {{ data.nombre }}
                                </span>
                            </div>
                            <div class="col-3 text-center f-18 text-general f-600">
                                {{ data.nombre_ciudad }}
                            </div>
                            <div class="col-1 text-center f-18 text-general f-600">
                                {{ data.cant_leecheros_activos }}
                            </div>
                            <div class="col-1 text-center f-18 text-general f-600">
                                {{ data.cant_leecheros_inactivos }}
                            </div>
                            <div class="col text-center f-18 text-general f-600">
                                {{ data.cant_clientes }}
                            </div>
                            <div class="col text-center f-18 text-general f-600">
                                {{ agregarSeparadoresNumero(data.productos) }}
                            </div>
                            <div class="col text-center f-18 text-general f-600">
                                {{ agregarSeparadoresNumero(data.unidades) }}
                            </div>
                            <div class="col text-center f-18 text-general f-600 tres-puntos">
                                {{ separadorNumero(data.valor) }}
                            </div>
                            <div class="col text-center f-18 text-general f-600">
                                {{ agregarSeparadoresNumero(data.calificacion, 1)
                                }}<i class="icon-star text-orange" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modales -->
        <modal ref="modalCedisInactivo" titulo="Lo sentimos!🙁" :no-aceptar="true" :no-cancelar="true" :cerrar="true">
            <div class="container mx-auto py-0">
                <span class="row text-general2 justify-center text-center">Este cedi ha sido deshabilitado.</span>
                <span class="row text-general2 justify-center text-center"> Para más información por favor contacta a un administrador.</span>
            </div>
        </modal>
        <modal-crear-cedis ref="CrearCedis" @listar="get_cedis" />
        <modal-mapa-cedis ref="modalMapaCedis" />
    </section>
</template>
<script>
import { mapGetters } from "vuex";
import Navbar from "~/services/cedis";
import Cedis from "~/services/cedis/index";
export default {
    components: {
        modalCrearCedis: () => import("./partials/modalCrearCedis"),
        modalMapaCedis: () => import("./partials/modalMapaCedis"),
    },
    data(){
        return {
            busqueda: "",
            cargando: true,
            cedis: [],
            info_cedis: {
                productos: 0,
                unidades: 0,
                valores: 0,
            },
            clicCedis:0,
            order: true
        };
    },
    computed: {
        ...mapGetters({
            id_moneda: "cedis/id_moneda",
            moneda: "cedis/moneda",
        }),
    },
    watch: {
        id_moneda(val,oldVal){
            if (oldVal == null) return
            this.cargando = true
            this.get_cedis();
        }
    },
    async mounted(){
        if (this.id_moneda == null){
            setTimeout(async() => {
                await this.get_cedis();
            }, 500);
        } else {
            await this.get_cedis();
        }
    },
    methods: {
        CrearCedis(){
            this.$router.push({name: 'admin.cedis.crear-cedis'})
            //this.$refs.CrearCedis.toggle();
        },
        mapaCedis(){
            this.$refs.modalMapaCedis.toggle(this.id_moneda, this.moneda);
        },
        ordenar(tipo, campo, orden = 'desc'){
            this.clicCedis = tipo
            let mismoTipo = tipo == this.clicCedis
            if (mismoTipo){
                this.order = !this.order
            }else{
                this.order = orden == 'desc'
            }
            let ordenar = this.order ? 'desc' : 'asc'
            this.cedis = _.orderBy(this.cedis, campo, ordenar)
        },
        iconFiltro(dato){
            if (this.clicCedis === dato){
                return this.order ? 'flecha-orden text-general abajo mover-abajo' : 'flecha-orden girar text-general arriba mover-arriba'
            } else {
                return 'text-gris'
            }
        },
        containerClass(dato){
            if (this.clicCedis === dato){
                return this.order ? ' text-general abajo' : 'girar text-general arriba'
            } else {
                return 'text-gris'
            }
        },
        async get_cedis(){
            try {
                let params = {
                    id_moneda: this.id_moneda,
                };
                this.cargando = true
                const { data } = await Cedis.get_cedis(params);
                this.cedis = data.cedis;
                this.info_cedis = data.info_cedis;
            } catch (e){
                this.error_catch(e);
            }finally{
                this.cargando = false
            }
        },
        async verCedis({ id: id_cedis},estado){
            if(!estado) return this.$refs.modalCedisInactivo.toggle()
            try {
                let cedis = [id_cedis]
                let params = {
                    cedis,
                }
                const {data} = await Navbar.crearCedisNavbar(params)
                this.$store.commit('cedis/set_cedis_nabvar', data.cedisNavbar)
                this.$store.commit('cedis/set_id_cedis_navbar')
                this.$store.commit('cedis/set_id_cedis')
                this.$router.push({ name: 'admin.cedis.ver', params: { id_cedis } })
            } catch (e){
                this.error_catch(e)
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.disabled-lista {
    opacity: 0.4;
}
.card-distribuccion {
  width: 490px;
  height: 250px;
}
.nombre-cedis {
  height: 38px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: pre-line;
  line-height: 19px;
}
.br-12 {
  border-radius: 12px;
}

a{
    color: initial !important;
}

// tablas ordenar
.girar{ transform: rotate(90deg)translate(16px, -1px); }
.mover-abajo{ left:-31px;top:-14px;z-index:3; }
.mover-arriba{ left:-31px;top:-22px;z-index:3; }


</style>
