import axios from 'axios'

const api = "cedis/categorias"

const Categorias = {

    createdCategorias: id_cedis => axios.post(`${api}/created`,{id_cedis}),
    getCedisCategorias: params => axios.get(`${api}/get-cedis-categorias`,params),
    getProveedoresEImpuestos: idCedis => axios.get(`${api}/${idCedis}/get-proveedores-impuestos`),

    actualizarCedisProducto: params => axios.post(`${api}/actualizar-cedis-producto`,params),
    actualizarCedisCategoria: params => axios.post(`${api}/actualizar-cedis-categoria`,params)
}

export default Categorias