<template>
    <section>
        <tabs :tabs="rutas" class="border-bottom" />
        <router-view />
    </section>
</template>

<script>

export default {
    middleware:['auth'],

    data(){
        return {
            rutas: [
                { titulo: 'Lista de CEDIS', ruta: 'admin.cedis.lista', can: 'tab1_cedis_lista' },
                { titulo: 'Estadísticas de comparativa', ruta: 'admin.cedis.comparativa', can: 'tab1_cedis_comparativa' },
            ]
        }
    },
    methods: {

    }
}
</script>
<style lang="css" scoped>
.br-t-12{ border-radius: 12px 12px 0px 0px !important; }
</style>
