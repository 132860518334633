<template>
    <section>
        <div class="d-flex mx-2 pt-2">
            <div class="col-4 px-0 bg-white br-t-12">
                <div class="row mx-0 align-items-center justify-center border-bottom py-2">
                    <div class="col-10 px-0">
                        <el-input v-model="buscar_cupon" placeholder="Buscar Carpeta" class="br-20 w-100" />
                    </div>
                </div>
                <div class="select-cupon custom-scroll overflow-auto">
                    <div v-for="(c, index) in carpetas.filter(data => !buscar_cupon || data.nombre.toLowerCase().includes(buscar_cupon.toLowerCase()))" :key="index" class="d-middle border-bottom px-2 py-2 cr-pointer" @click="seleccionarCarpeta(c)">
                        <div class="br-10" :class="idCarpetaActiva == c.id ? 'bg-general' : ''" style="height:40px;width:8px;" />
                        <div class="col text-general tres-puntos f-15 pl-1">
                            {{ c.nombre }}
                        </div>
                        <div v-show="!c.estado" class="col-auto px-0">
                            <i class="icon-lock f-22 text-general" />
                        </div>
                        <div class="col-auto px-1">
                            <el-tooltip placement="bottom" content="Cupones en esta carpeta" effect="light">
                                <div class="bg-general3 px-1 br-10 text-white">
                                    <i class="icon-cupon mr-2 f-15" />
                                    {{ c.cant_cupones }}
                                </div>
                            </el-tooltip>
                        </div>
                        <i class="icon-angle-right f-22" :class="idCarpetaActiva == c.id ? 'text-general' : 'text-gris'" />
                    </div>
                </div>
            </div>
            <div v-if="idCarpetaActiva" class="col ml-3 bg-white custom-scroll overflow-auto px-0 cont-cupon">
                <div class="d-flex mx-0 pb-2 my-2 aling-items-center">
                    <div class="col-auto text-general d-middle f-17">
                        {{ carpetaSeleccionada.nombre }}
                    </div>
                </div>
                <!-- Cupones vigentes -->
                <div class="row mx-0">
                    <div class="col-12 px-0">
                        <el-table :data="cuponesVigentes" header-row-class-name="text-general f-17" class="cr-pointer" style="width: 100%" @row-click="irDetalle">
                            <el-table-column class-name="text-center" width="50">
                                <template slot-scope="{row}">
                                    <i v-show="row.estado === 0" class="icon-lock f-20 text-general" />
                                </template>
                            </el-table-column>
                            <el-table-column prop="nombre" label="Cupón vigente" min-width="200" />
                            <el-table-column label="Tipo" class-name="text-center" min-width="150">
                                <template slot-scope="{row}">
                                    <p> {{ funTipoCupon(row.tipo) }}</p>
                                </template>
                            </el-table-column>
                            <el-table-column prop="pais" label="País" min-width="150" />
                            <el-table-column label="Cedis" class-name="text-center" min-width="80">
                                <template slot-scope="{row}">
                                    <div class="row mx-0 justify-center">
                                        <div class="col-auto">
                                            <div class="bg-general3 br-10 text-white px-2 py-1">
                                                {{ row.cant_cedis }}
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="Usados" class-name="text-center" min-width="150">
                                <template slot-scope="{row}">
                                    <div class="row mx-0 justify-center">
                                        <div class="col-auto">
                                            <p v-if="row.tipo === 1">{{ row.cant_usada }}</p>
                                            <p v-if="row.tipo === 2">{{ row.cant_usada }}({{ row.cant_usada_bono }})</p>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="Disponibles" class-name="text-center" min-width="150">
                                <template slot-scope="{row}">
                                    <div class="row mx-0 justify-center">
                                        <div class="col-auto">
                                            <p v-if="row.limite_total === null">Sin Limite</p>
                                            <p v-if="row.limite_total !== null">{{ row.cant_usada }}/{{ row.limite_total }}</p>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <!-- Cupones vencidos -->
                <div v-show="cuponesVencidos.length" class="row mx-0 mt-4">
                    <div class="col-12 px-0">
                        <el-table :data="cuponesVencidos" header-row-class-name="text-general f-17" class="cr-pointer" style="width: 100%" @row-click="irDetalle">
                            <el-table-column class-name="text-center" width="50">
                                <template slot-scope="{row}">
                                    <i v-show="row.estado === 0" class="icon-lock f-20 text-general" />
                                </template>
                            </el-table-column>
                            <el-table-column prop="nombre" label="Cupón vencido" min-width="200" />
                            <el-table-column label="Tipo" class-name="text-center" min-width="150">
                                <template slot-scope="{row}">
                                    <p> {{ funTipoCupon(row.tipo) }}</p>
                                </template>
                            </el-table-column>
                            <el-table-column prop="pais" label="País" min-width="150" />
                            <el-table-column label="Cedis" class-name="text-center" min-width="80">
                                <template slot-scope="{row}">
                                    <div class="row mx-0 justify-center">
                                        <div class="col-auto">
                                            <div class="bg-general3 br-10 text-white px-2 py-1">
                                                {{ row.cant_cedis }}
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="Usados" class-name="text-center" min-width="150">
                                <template slot-scope="{row}">
                                    <div class="row mx-0 justify-center">
                                        <div class="col-auto">
                                            <p v-if="row.tipo === 1">{{ row.cant_usada }}</p>
                                            <p v-if="row.tipo === 2">{{ row.cant_usada }}({{ row.cant_usada_bono }})</p>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="Disponibles" class-name="text-center" min-width="150">
                                <template slot-scope="{row}">
                                    <div class="row mx-0 justify-center">
                                        <div class="col-auto">
                                            <p v-if="row.limite_total === null">Sin Limite</p>
                                            <p v-if="row.limite_total !== null">{{ row.limite_total }}</p>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
            <div v-show="idCarpetaActiva === null" class="col ml-3 custom-scroll overflow-auto px-0 bg-white cont-cupon">
                <div class="text-center f-15 f-600 d-middle-center flex-column mx-auto h-100" style="width:225px;">
                    <div class="d-middle-center bg-whitesmoke br-5" style="width:170px;height:170;">
                        <img height="120" src="/img/no-imagen/click.svg" alt="" />
                    </div>
                    <p>Seleccione una de las carpetas activas del panel de la izquierda.</p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Cupones from '~/services/cupones/cupones'
export default {
    data(){
        return {
            buscar_cupon: '',
            active_cupon: null,
            cuponActivo: false,
            carpetas:[],
            idCarpetaActiva:null,
            carpetaSeleccionada:{},
            cuponesVencidos:[],
            cuponesVigentes:[],


        }
    },
    computed:{
        id_cedis(){
            return Number(this.$route.params.id_cedis)
        }
    },
    mounted(){
        this.listarCarpetas()
    },
    methods: {
        async listarCarpetas(){
            try {

                const {data} = await Cupones.listarCarpetas(this.id_cedis)
                this.carpetas = data.carpetas

            } catch (e){
                this.error_catch(e);
            }
        },
        async seleccionarCarpeta(carpeta){
            this.idCarpetaActiva = carpeta.id
            this.carpetaSeleccionada = carpeta
            //this.$store.commit('cupones/cupones/set_id_carpeta',carpeta.id)

            try {
                const {data} = await Cupones.listarCuponesXCedis(this.idCarpetaActiva,this.id_cedis)
                this.cuponesVigentes = data.vigentes
                this.cuponesVencidos = data.vencidos
            } catch (e){
                this.error_catch(e);
            }

        },
        funTipoCupon(tipo){
            if(tipo === 1){
                return "Aplicar Ya"
            }
            if(tipo === 2){
                return "Bono Fúturo"
            }
        },
        irDetalle(row){
            //console.log(row);
            if(row.tipo === 1){
                this.$router.push({name:'admin.cedis.cupones.ver.informacion1',params:{id_cupon:row.id}})
            }
            if(row.tipo === 2){
                this.$router.push({name:'admin.cedis.cupones.ver.informacion2',params:{id_cupon:row.id}})
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.select-cupon{
    height: calc(100vh - 299px);
}
.cont-cupon{
    border-radius: 12px 12px 0px 0px;
}
</style>
