import axios from 'axios'

const API = 'cedis-ordenes-compras'

const ENDPOINTS = {
    detectarFechas(params){
        return axios(`${API}/fechas`,{params})
    },
    getListarOrdenesCompra(params){
        return axios(`${API}/listado`, {params})
    },
    getInfoModalGuardarOC(id){
        return axios(`${API}/${id}/proveedores`)
    },
    getOrdenCompra(id){
        return axios(`${API}/${id}/orden-compra`)
    },
    postGuardarOrdenCompra(form){
        return axios.post(`${API}/guardar`, form)
    },
    putActualizarOrdenCompra(id,form){
        return axios.post(`${API}/${id}/actualizar`, form)
    },
    putConfirmarOrden(id,form){
        return axios.put(`${API}/${id}/confirmar`, form)
    },
    postEnviarComentario(form){
        return axios.post(`${API}/comentario`, form)
    },
    deleteOrdenCompra(id){
        return axios.delete(`${API}/${id}/eliminar`)
    },
    getOrdenesCalendario(){

    }

};

export default ENDPOINTS
