<template>
    <section>
        <cargando v-if="cargando" />
        <div class="row mx-0">
            <div class="col-12 bg-white pb-3 br-8">
                <FullCalendar
                ref="fullCalendar"
                :options="calendarOptions"
                />
            </div>
        </div>
    </section>
</template>

<script>
import "@fullcalendar/core/vdom"; // solves problem with Vite
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import esLocale from "@fullcalendar/core/locales/es";
import interaction from "@fullcalendar/interaction";
import moment from "moment";
import {mapGetters} from 'vuex'
import Ordenes from "~/services/cedis/cedis_ordenes_compras"
export default {
    components: {
        FullCalendar,
    },
    data(){
        return {
            calendarOptions: {
                plugins: [dayGridPlugin, timeGridPlugin, interaction],
                firstDay:0,
                locale: esLocale,
                allDaySlot:false,
                initialView: "timeGridWeek",
                events:[],
                selectable: true,
                selectMirror: true,
                select: this.handleDateSelect,
                height: "calc(100vh - 260px)",
                headerToolbar: {
                    left: "",
                    center: "",
                    right: "",
                },
                slotLabelFormat:{
                    hour: 'numeric',
                    minute: '2-digit',
                    omitZeroMinute: false,
                    meridiem: 'short',
                    hour12: true
                },
                dayHeaderFormat:{
                    weekday: 'long', month: 'short', day: 'numeric', omitCommas: true
                },
                eventClick:this.handleEventClick
            },
            cargando:false,
            ordenes:[]
        };
    },
    computed:{
        ...mapGetters({
            filtros: `cedis/ordenes_compras/filtros`,
            rangoFechas: `cedis/ordenes_compras/rangoFechas`,
            detectarfiltro: `cedis/ordenes_compras/detectarfiltro`,

        }),
        idCedis(){
            return Number(this.$route.params.id_cedis)
        }
    },
    watch: {
        rangoFechas(value){
            this.$refs.fullCalendar.getApi().gotoDate(value[0])
            this.loadInformacion()
        },
        detectarfiltro(){
            this.filtrarRegistros();
        },
    },
    mounted(){
        this.loadInformacion()
    },
    methods: {
        async loadInformacion(){
            try {
                if(this.rangoFechas.length == 0){
                    this.loadInformacion()
                    return
                }
                this.cargando = true
                let params = {
                    id_cedis:this.idCedis,
                    fecha_inicial: this.rangoFechas[0],
                    fecha_final: this.rangoFechas[1]
                };
                const {data} = await Ordenes.getListarOrdenesCompra(params);
                this.ordenes = data.ordenes
                this.setEvents(data.ordenes)


            } catch (error){
                this.error_catch(error)
            } finally {
                this.cargando = false
            }
        },
        handleDateSelect({ start, end }){
            if (start < moment()){
                this.notificacion( "Alerta", "No puede crear Ordenes de Compra menores a hoy", "warning" );
                return;
            }
            // console.log(moment(end).date());
            if (moment(end).date() > moment(start).date()){
                this.notificacion( "Alerta", "No puede crear Ordenes de Compra en rangos de dias diferentes", "warning" );
                return;
            }

            this.$emit('agregar',{start,end})

        },
        setEvents(ordenes){
            let events = []
            for (const o of ordenes){
                events.push({
                    id: o.id,
                    title: `OC: ${o.id} \n ${o.proveedor ? o.proveedor : ''}`,
                    description:o.proveedor,
                    start: moment(o.hora_inicio).format('YYYY-MM-DD HH:mm:ss'),
                    end: moment(o.hora_fin).format('YYYY-MM-DD HH:mm:ss'),
                    color: o.estado === 1 ? "#9ea1a5" : o.estado === 2 ? "#007bff" : "#409cff",
                })
            }
            this.calendarOptions.events = events
        },
        handleEventClick({event}){
            this.$router.push({name: 'admin.cedis.ordenes-compra.detalle', params: {id_oc: Number(event.id)}});
        },
        async filtrarRegistros(){
            if (this.ordenes.length < 1){return}
            let parametros = [];
            let datosFiltrados = [];
            if (this.filtros.pendiente === true){
                parametros.push(1);
            }
            if (this.filtros.confirmadas === true){
                parametros.push(2);
            }
            if (this.filtros.incompletas === true){
                parametros.push(3);
            }
            if (parametros.length === 0){
                this.setEvents([])
                return;
            }
            if (parametros.length === 3){
                datosFiltrados = this.ordenes.filter((item) => item.estado === parametros[0] || item.estado === parametros[1] || item.estado === parametros[2]);
            }
            if (parametros.length === 2){
                datosFiltrados = this.ordenes.filter((item) => item.estado === parametros[0] || item.estado === parametros[1]);
            }
            if (parametros.length === 1){
                datosFiltrados = this.ordenes.filter((item) => item.estado === parametros[0]);
            }

            this.setEvents(datosFiltrados)
        },
    },
};
</script>
