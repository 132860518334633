import axios from 'axios'

const API_PREFIX = 'cedis-comparativa'

const ENDPOINTS = {
    graficas_barras: params => axios(`${API_PREFIX}/graficas-barras`, { params }),
    comparativas: params => axios(`${API_PREFIX}/comparativas`, { params }),
    informe: params => axios(`${API_PREFIX}/informe`, { params })
};

export default ENDPOINTS
