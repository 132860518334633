<template>
    <section class="bg-white custom-scroll overflow-auto" style="height:calc(100vh - 248px)">
        <cargando v-if="cargando" />
        <template v-if="ordenesAgrupadas.length > 0">
            <div v-for="(orden,x) in ordenesAgrupadas" :key="x" class="row m-3 mb-5">
                <div class="col-12 d-middle bg-fondo br-8 text-general f-17" style="height:32px;" @click="$router.push({name: 'admin.cedis.ordenes-compra.detalle'})">
                    {{ orden.fecha | helper-fecha('dddd DD MMMM YYYY') }}
                </div>
                <div v-for="(item,z) in orden.items" :key="z" class="col-12 d-middle px-0 br-8 border mt-2" style="height:32px;">
                    <template v-if="item.estado === 1">
                        <div class="bg-blue col d-middle tres-puntos h-100 br-8">
                            <div class="tres-puntos text-general2 f-17">
                                <span class="f-17 text-white tres-puntos">
                                    {{ item.hora_inicio | helper-fecha('hh:mm a') }} - {{ item.hora_fin | helper-fecha('hh:mm a') }}
                                </span>
                            </div>
                            <div class="col-2 ml-2" />
                            <div class="col pl-3 tres-puntos text-general2 f-17">
                                <span class="f-17 text-white tres-puntos cr-pointer" @click="irDetalle(item.id)">
                                    OC {{ item.id }}
                                </span>
                            </div>
                            <div class="col" />
                        </div>
                    </template>
                    <template v-if="item.estado != 1">
                        <div class="col tres-puntos text-general2 f-17">
                            {{ item.hora_inicio | helper-fecha('hh:mm a') }} - {{ item.hora_fin | helper-fecha('hh:mm a') }}
                        </div>
                        <div class="col pl-1 text-general2 f-17 ml-4">
                            <i class="icon-ok-circled-outline text-green f-18" />
                            <span class="cr-pointer" @click="irDetalle(item.id)">OC {{ item.id }}</span>
                            <span v-if="item.estado === 2" class="ml-3">(Completo)</span>
                            <span v-if="item.estado === 3" class="ml-3">(Incompleto)</span>
                        </div>
                        <div class="cant-invent p-1 ml-4" :class="item.total_importados > 0 ? 'bg-green' : 'bg-gris'">
                            {{ item.total_importados }}
                        </div>
                        <div class="col tres-puntos px-2 text-general2">
                            Inventarios importados
                        </div>
                    </template>
                    <div class="col-7 text-general2 text-center">
                        <template v-if="item.proveedor">
                            <i class="icon-proveedor f-18" />
                            {{ item.proveedor }}
                        </template>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="row m-3 mb-5">
                <div class="col-12">
                    <el-alert
                    type="info"
                    >
                        <div class="text-general f-20">
                            <i class="icon-info-circled-alt" />
                            <span class="ml-2">{{ mensajeAlerta }}</span>
                        </div>
                    </el-alert>
                </div>
            </div>
        </template>
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
import Ordenes from "../../../../services/cedis/cedis_ordenes_compras"
import _ from 'lodash'
import moment from 'moment'

export default {
    data(){
        return {
            cargando: false,
            ordenes: [],
            ordenesAgrupadas: [],
            mensajeAlerta: "En esta semana no habrá visitas de proveedores para entrega de producto",
        }
    },
    computed:{
        ...mapGetters({
            filtros: `cedis/ordenes_compras/filtros`,
            rangoFechas: `cedis/ordenes_compras/rangoFechas`,
            detectarfiltro: `cedis/ordenes_compras/detectarfiltro`,
        }),
        idCedis(){
            return Number(this.$route.params.id_cedis)
        }
    },
    watch: {
        rangoFechas(value){
            this.validarWatch()
        },
        detectarfiltro(){
            this.filtrarRegistros();
        },
    },
    created(){
        this.cargando = true;
        this.getListarOrdenesCompra();
    },
    methods: {
        validarWatch(){
            if (!this.cargando){
                this.getListarOrdenesCompra();
            }
        },
        async getListarOrdenesCompra(){
            this.cargando = true;
            try {
                let params = {
                    id_cedis:this.idCedis,
                    fecha_inicial: this.rangoFechas[0],
                    fecha_final: this.rangoFechas[1]
                };
                const {data} = await Ordenes.getListarOrdenesCompra(params);
                this.ordenes = data.ordenes;
                this.ordenesAgrupadas = _.chain(this.ordenes).groupBy('fecha').map((el, key) => ({ fecha: key, items: el })).value()
                if (data.ordenes.length < 1){
                    this.mensajeAlerta = "En esta semana no habrá visitas de proveedores para entrega de producto";
                    this.cargando = false;
                    return;
                }
                this.filtrarRegistros();
                this.cargando = false;
            } catch (e){
                this.cargando = false;
                this.error_catch(e)
            }
        },
        async filtrarRegistros(){
            if (this.ordenes.length < 1){return}
            let parametros = [];
            let datosFiltrados = [];
            if (this.filtros.pendiente === true){
                parametros.push(1);
            }
            if (this.filtros.confirmadas === true){
                parametros.push(2);
            }
            if (this.filtros.incompletas === true){
                parametros.push(3);
            }
            if (parametros.length === 3){
                datosFiltrados = this.ordenes.filter((item) => item.estado === parametros[0] || item.estado === parametros[1] || item.estado === parametros[2]);
            }
            if (parametros.length === 2){
                datosFiltrados = this.ordenes.filter((item) => item.estado === parametros[0] || item.estado === parametros[1]);
            }
            if (parametros.length === 1){
                datosFiltrados = this.ordenes.filter((item) => item.estado === parametros[0]);
            }
            if (parametros.length < 1){
                this.ordenesAgrupadas = [];
                this.cargando = false;
                this.mensajeAlerta = "No existen registros de acuerdo a la selección de filtros.";
                return;
            }
            this.ordenesAgrupadas = _.chain(datosFiltrados).groupBy('fecha').map((el, key) => ({ fecha: key, items: el })).value()
            this.cargando = false;
            this.mensajeAlerta = "En esta semana no habrá visitas de proveedores para entrega de producto";
        },
        irDetalle(idOrdenCompra){
            this.$router.push({name: 'admin.cedis.ordenes-compra.detalle', params: {id_oc: idOrdenCompra, id_cedis: this.idCedis}});
        },
    },
}
</script>
<style lang="scss" scoped>
.bg-blue{
    background-color: #2196F3;
}
.cant-invent{
    min-width: 24px;
    height: 24px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    font-size: 17px;
}
</style>
