import axios from 'axios'

const API_PREFIX = "alianzas";

const ENDPOINTS = {
    comision_x_meses: params => axios(`${API_PREFIX}/comision-x-meses`, { params }),
    ver_mas_leecheros: params => axios(`${API_PREFIX}/ver-mas-leecheros`, { params }),
    informe_leechero: params => axios(`${API_PREFIX}/informe-leechero`, { params }),
    lista_leecheros: id_cedis => axios(`${API_PREFIX}/${id_cedis}/lista-leecheros`),

    // pagos
    getPagosComision: (idLeechero, params) => axios(`${API_PREFIX}/get-pagos-comision/${idLeechero}`, { params }),
    // Descuentos lista
    getGraficaDescuentos: idCedis => axios(`${API_PREFIX}/${idCedis}/descuentos-lista`),
    getGraficaDescuentosComisiones: idCedis => axios(`${API_PREFIX}/${idCedis}/descuentos-lista/comisiones`),
    getParametrosCedis: idCedis => axios(`${API_PREFIX}/cedis/${idCedis}/parametros`),
    postDescuentoTienda: payload => axios.post(`${API_PREFIX}/beneficio-descuento/guardar`, payload),
    getCondiciones: () => axios.get(`${API_PREFIX}/condiciones`),
    cambiarCondicionLeecheros: payload => axios.post(`${API_PREFIX}/condiciones/actualizar`, payload),
};

export default ENDPOINTS
