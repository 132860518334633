import axios from 'axios'

const API_PREFIX = "cupones-estadisticas-leecheros";

const ENDPOINTS = {
    comparativos: params => axios(`${API_PREFIX}/comparativos`, { params }),
    cuponesCedis: params => axios(`${API_PREFIX}/cupones-cedis`, { params }),
    aplicacionCuponesFechas: params => axios(`${API_PREFIX}/cupones-fechas`, { params }),
    aplicacionCuponesInforme: params => axios(`${API_PREFIX}/cupones-informe`, { params }),
    cuponesIngresosInforme: params => axios(`${API_PREFIX}/cupones-ingresos`, { params }),
};

export default ENDPOINTS
