<template>
    <section>
        <tabs :tabs="rutas" class="border-bottom" />
        <router-view />
    </section>
</template>

<script>
export default {
    data(){
        return {
            rutas: [
                {
                    titulo: 'Comisiones',
                    ruta: 'admin.cedis.alianzas.comisiones'
                },
                {
                    titulo: 'Descuentos de lista',
                    ruta: 'admin.cedis.alianzas.descuento-lista'
                },
            ]
        }
    },
    methods: {
        
    }
}
</script>

<style lang="scss">
.br-12{ border-radius: 12px ; }
.card-comision{
    width: 204px;
    height: 200px;
}
.shadow-edit{
    box-shadow: 0px 3px 6px #00000029 ;
}
a{
    text-decoration: none !important;
    &:hover{
        color: var(--text-general) !important;
    }
}
</style>
