
<template>
    <section>
        <div class="row mx-0 align-items-center mb-4">
            <div class="btn-back" @click="$router.back()">
                <i class="icon-left f-12" />
            </div>
            <div class="col-auto f-18 f-600 text-general">
                Crear centro de distribución
            </div>
            <div class="bg-general text-white px-5 br-8 ml-auto cr-pointer d-flex align-items-center" style="height:32px;" @click="crear_cedis">
                Guardar
            </div>
        </div>
        <ValidationObserver ref="validacion" tag="div" class="custom-scroll overflow-auto pr-3 mt-3" style="height:calc(100vh - 136px);">
            <div class="bg-white br-12 py-3 px-3">
                <div class="row mx-0">
                    <div class="col-auto" />
                    <div class="col-auto" />
                    <div class="col-auto text-general f-17 f-500">
                        Datos
                    </div>
                </div>
                <div class="row mx-0 mt-3">
                    <div class="col-auto px-5" />
                    <div class="col">
                        <div class="row mx-0">
                            <div class="col">
                                <div class="row mx-0">
                                    <div style="width:18px;height:19px;" />
                                    <ValidationProvider v-slot="{errors}" tag="div" rules="required|max:30" name="nombre cedis" class="col-8">
                                        <label class="pl-3 text-general f-14"> Nombre del Cedis </label>
                                        <el-input v-model="model.nombre" class="w-100" size="small" maxlength="30" show-word-limit placeholder="Nombre" />
                                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="col">
                                <div class="row mx-0">
                                    <div style="width:18px;height:19px;" />
                                    <ValidationProvider v-slot="{errors}" tag="div" rules="required" name="moneda" class="col-8">
                                        <label class="pl-3 text-general f-14"> Moneda </label>
                                        <el-select v-model="model.idm_moneda" size="small" placeholder="Moneda" class="w-100">
                                            <el-option
                                            v-for="item in monedas"
                                            :key="item.id"
                                            :label="item.nombre"
                                            :value="item.id"
                                            />
                                        </el-select>
                                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                        <!-- Separador -->
                        <div class="row mx-0 py-3" />
                        <!-- Separador -->
                        <div class="row mx-0">
                            <div class="col">
                                <div class="row mx-0">
                                    <ValidationProvider vid="inventario" tag="div" rules="" class="col-12 d-flex">
                                        <el-checkbox v-model="model.cedis_inventario" class="check-red">
                                            Almacenar inventario en CEDIS diferente
                                        </el-checkbox>
                                    </ValidationProvider>
                                    <div style="width:18px;height:19px;" />
                                    <div v-if="model.cedis_inventario" class="col-8 mt-3">
                                        <ValidationProvider v-slot="{errors}" rules="required_if:inventario,true" name="cedis" tag="div">
                                            <label class="pl-3 text-muted2"> Cedis </label>
                                            <el-select v-model="model.id_cedis_stock" size="small" placeholder="Cedis" class="w-100">
                                                <el-option
                                                v-for="item in cedis"
                                                :key="item.id"
                                                :label="item.nombre"
                                                :value="item.id"
                                                />
                                            </el-select>
                                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="row mx-0">
                                    <div class="col-12 px-2">
                                        <ValidationProvider vid="catalogo" rules="">
                                            <el-checkbox v-model="model.cedis_catalogo" class="check-red">
                                                Usar catálogo de CEDIS diferente
                                            </el-checkbox>
                                        </ValidationProvider>
                                    </div>
                                    <div style="width:18px;height:19px;" />
                                    <div v-if="model.cedis_catalogo" class="col-8 px-2 mt-3">
                                        <ValidationProvider v-slot="{errors}" rules="required_if:catalogo,true" name="cedis">
                                            <label class="pl-3 text-muted2"> Cedis </label>
                                            <el-select v-model="model.id_cedis_catalogo" size="small" placeholder="Cedis" class="w-100">
                                                <el-option
                                                v-for="item in cedis"
                                                :key="item.id"
                                                :label="item.nombre"
                                                :value="item.id"
                                                />
                                            </el-select>
                                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Separador -->
                        <div class="row mx-0 py-3" />
                        <!-- Separador -->
                        <div class="row mx-0">
                            <div class="col d-flex">
                                <div style="width:18px;height:19px;" />
                                <div class="col-8">
                                    <p class="ml-2 text-general f-14">Impuestos</p>
                                    <el-select v-model="model.impuestos" size="small" class="w-100">
                                        <el-option label="Sin Impuestos" :value="0" />
                                        <el-option label="Valores +IVA" :value="1" />
                                        <el-option label="IVA incluido" :value="2" />
                                        <el-option label="IVA incluido sin etiqueta" :value="3" />
                                    </el-select>
                                </div>
                            </div>
                            <div class="col">
                                <div class="row mx-0 align-items-center" style="height:53px;">
                                    <div class="col-auto px-2">
                                        <el-checkbox v-model="model.estado" class="check-red">
                                            Activar Cedis
                                        </el-checkbox>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Separador -->
                        <div class="row mx-0 py-3" />
                        <!-- Separador -->
                        <div class="row mx-0">
                            <div class="col d-flex">
                                <div style="width:18px;height:19px;" />
                                <ValidationProvider v-slot="{errors}" rules="required|max:20" name="teléfono" tag="div" class="col-8">
                                    <label class="pl-3 text-general f-14"> Teléfono </label>
                                    <el-input v-model="model.telefono" size="small" class="w-100" maxlength="20" show-word-limit placeholder="Teléfono" @change="buscarDireccion" />
                                    <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                </ValidationProvider>
                            </div>
                            <div class="col">
                                <div class="row mx-0">
                                    <div style="width:18px;height:19px;" />
                                    <div class="col-8">
                                        <ValidationProvider v-slot="{errors}" rules="required" name="responsable">
                                            <label class="pl-3 text-general f-14"> Responsable </label>
                                            <el-select v-model="model.id_user" size="small" placeholder="Responsable" class="w-100">
                                                <el-option
                                                v-for="item in resposanbles"
                                                :key="item.id"
                                                :label="item.nombre"
                                                :value="item.id"
                                                />
                                            </el-select>
                                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mx-0">
                            <div class="col-12 ml-4 mt-5">
                                <el-form>
                                    <el-form-item class="text-general" label="Operador logístico">
                                        <el-radio-group v-model="model.operacion_logistica">
                                            <el-radio label="1">
                                                Operador interno
                                            </el-radio>
                                            <el-radio label="2">
                                                Operador externo
                                            </el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                </el-form>
                            </div>
                        </div>
                        <div class="row mx-0">
                            <div class="col-12 ml-4 mt-5">
                                <el-form>
                                    <el-form-item class="text-general" label="Productos que no se pueden vender sin stock">
                                        <el-radio-group v-model="model.stock_agotado">
                                            <el-radio label="1">
                                                Con productos disponibles
                                                <el-tooltip class="item" effect="light" content="Descripción" placement="top-start">
                                                    <i class="icon-info-circled-alt f-18 text-general" />
                                                </el-tooltip>
                                            </el-radio>
                                            <el-radio label="2">
                                                Productos reservados
                                                <el-tooltip class="item" effect="light" content="Descripción" placement="top-start">
                                                    <i class="icon-info-circled-alt f-18 text-general" />
                                                </el-tooltip>
                                            </el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                </el-form>
                            </div>
                        </div>
                    </div>
                    <div class="col-2 px-0">
                        <div class="row mx-0 mb-3">
                            <slim-cropper ref="foto_cedis" :options="slimOptions" class="border" style="height:150px;background:#F8F9FF;width:150px;border-radius:12px;" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="bg-white br-12 py-4 px-3 mt-3">
                <div class="row mx-0">
                    <div class="col-auto" />
                    <div class="col-auto" />
                    <div class="col-auto text-general f-17 f-500">
                        Gaming
                    </div>
                </div>
                <div class="row mx-0 mt-3 align-items-center">
                    <div class="col-auto" />
                    <div class="col-auto" />
                    <div class="col-auto" />
                    <div class="col-auto px-4" />
                    <div class="col-auto text-general">
                        Usuario que tendrán los beneficios de gaming
                    </div>
                    <div class="col-auto d-middle">
                        <input v-model="model.gaming" :value="0" type="radio" class="option-input radio black" />
                        <span class="ml-14">
                            Ninguno
                        </span>
                    </div>
                    <div class="col-auto d-middle">
                        <input v-model="model.gaming" :value="1" type="radio" class="option-input radio black" />
                        <span class="ml-14">
                            {{ $config.vendedor }}
                        </span>
                    </div>
                    <div class="col-auto d-middle">
                        <input v-model="model.gaming" :value="2" type="radio" class="option-input radio black" />
                        <span class="ml-14">
                            {{ $config.cliente }}
                        </span>
                    </div>
                    <div class="col-auto d-middle">
                        <input v-model="model.gaming" :value="3" type="radio" class="option-input radio black" />
                        <span class="ml-14">
                            Ambos
                        </span>
                    </div>
                </div>
            </div>
            <div class="bg-white br-12 py-4 px-3 mt-3">
                <div class="row mx-0">
                    <div class="col-auto" />
                    <div class="col-auto" />
                    <div class="col-auto text-general f-17 f-500">
                        Formas de pago
                    </div>
                </div>
                <div class="row mx-0 mt-3 align-items-center">
                    <div class="col-auto" />
                    <div class="col-auto" />
                    <div class="col-auto" />
                    <div class="col-auto px-4" />
                    <div class="col pl-0 text-general">
                        <div class="row mx-0">
                            <div v-for="(data, index) in model.formaPagos" :key="index" class="col-auto d-middle mr-4 mb-4">
                                <el-checkbox v-model="data.checked" class="check-dark mr-3" @change="changeFormaPago($event, data.id, data.column)" />
                                <span class="text-general">{{ data.nombre }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bg-white br-12 py-3 pb-4 px-3 mt-3">
                <div class="row mx-0">
                    <div class="col-auto" />
                    <div class="col-auto" />
                    <div class="col-auto text-general f-17 f-500">
                        Parámetros temporales
                    </div>
                </div>
                <div class="row mx-0 mt-4">
                    <div class="col-auto" />
                    <div class="col-auto" />
                    <div class="col-auto" />
                    <div class="col-auto" />
                    <div class="col">
                        <div class="row mx-0">
                            <div class="col-auto">
                                <p class="text-general f-14 ml-2">Minutos auto confirmación</p>
                                <ValidationProvider v-slot="{errors}" rules="required|numeric" tag="div" class="col px-0" name="Horas auto confirmación">
                                    <el-input v-model="model.horas_autoconfirmacion" size="small" class="w-100" placeholder="Número de horas" />
                                    <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                </ValidationProvider>
                                <el-tooltip content="Horas límite que tiene el cliente para aceptar los cambios realizados en el listado de los productos del pedido. Antes que se confirmen automáticamente." placement="bottom" effect="light">
                                    <i class="icon-info-circled-alt f-18 text-general" />
                                </el-tooltip>
                            </div>
                            <div class="col-auto">
                                <p class="text-general f-14 ml-2">Días de reclamo</p>
                                <ValidationProvider v-slot="{errors}" rules="required|numeric" tag="div" class="col px-0" name="Días de reclamo">
                                    <el-input v-model="model.dias_reclamo" size="small" class="w-100" placeholder="Número de días" />
                                    <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                </ValidationProvider>
                                <el-tooltip :content="`Días límite que tiene el cliente para solicitar un reclamo al ${$config.vendedor} una vez recibido el pedido.`" placement="bottom" effect="light">
                                    <i class="icon-info-circled-alt f-18 text-general" />
                                </el-tooltip>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="bg-white br-12 py-3 pb-4 px-3 mt-3">
                <div class="row mx-0">
                    <div class="col-auto" />
                    <div class="col-auto" />
                    <div class="col-auto text-general f-17 f-500">
                        Descuento de lista por default
                    </div>
                </div>
                <div class="row mx-0 mt-4">
                    <div class="col-auto" />
                    <div class="col-auto" />
                    <div class="col-auto" />
                    <div class="col-auto" />
                    <div class="col">
                        <div class="row mx-0">
                            <div class="col-auto">
                                <p class="text-general f-17 ml-2">Los nuevos {{$config.vendedor}} del cedis pueden acogerse al beneficio de descuento de lista.</p>
                            </div>
                        </div>
                        <div class="row mx-2 mt-3">
                            <div class="col-auto d-middle text-general">
                                <input v-model="model.descuentoLeecheros" :value="1" type="radio" class="option-input radio black" />
                                <span class="ml-14 f-15">
                                    De manera automatica
                                </span>
                            </div>
                        </div>
                        <div class="row mx-2 mt-3">
                            <div class="col-auto d-middle text-general">
                                <input v-model="model.descuentoLeecheros" :value="2" type="radio" class="option-input radio black" />
                                <span class="ml-14 f-15">
                                    De manera manual
                                </span>
                            </div>
                        </div>

                        <div v-if="model.descuentoLeecheros === 2" class="row mx-5 mt-2">
                            <div class="col-auto">
                                <p class="f-12 text-general ml-2">Clasificación</p>
                                <ValidationProvider v-slot="{errors}" rules="required" name="Clasificación">
                                    <el-select v-model="model.idClasificacion" size="small" placeholder="Seleccionar">
                                        <el-option
                                        v-for="item in listaClasificacion"
                                        :key="item.id"
                                        :label="item.nombre"
                                        :value="item.id"
                                        />
                                    </el-select>
                                    <div>
                                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="row mx-2 mt-3">
                            <div class="col-auto d-middle text-general">
                                <input v-model="model.descuentoLeecheros" :value="0" type="radio" class="option-input radio black" />
                                <span class="ml-14 f-15">
                                    No acogerlos al beneficio.
                                </span>
                            </div>
                        </div>
                        <div class="row mx-2 mt-3">
                            <div class="col-auto d-middle text-general">
                                <el-checkbox v-model="model.descuentoClientes" :true-label="1" :false-label="0" class="check-green" />
                                <span class="ml-3 f-15">Los clientes de los {{$config.vendedor}}s nuevos del cedis pueden acogerse al beneficio de descuento de lista</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="bg-white br-12 py-3 pb-4 px-3 mt-3">
                <div class="row mx-0">
                    <div class="col-auto" />
                    <div class="col-auto" />
                    <div class="col-auto text-general f-17 f-500">
                        Horario laboral
                    </div>
                </div>
                <div class="row mx-0 mt-4">
                    <div class="col-auto" />
                    <div class="col-auto" />
                    <div class="col-auto" />
                    <div class="col-auto" />
                    <div class="col">
                        <div class="row mx-0 align-items-center mt-4">
                            <div class="col-auto px-0 f-15 text-general">
                                Indique los días y horarios que reciben pedidos el CEDIS y su {{ $config.vendedor }}
                            </div>
                        </div>
                        <div class="row mx-0 mt-4">
                            <div class="col-auto px-0 f-15">
                                <el-checkbox v-model="model.horariosUso" :true-label="1" :false-label="0" class="check-green text-general" />
                                <span class="ml-2">Seleccione esta opción si el Cedis realiza entregas programadas</span>
                            </div>
                        </div>
                        <div class="row mx-2 align-items-center mt-3">
                            <div class="col-12 mr-5">
                                <p class="f-13 mx-2 text-general">Mensaje de entrega</p>
                                <el-input v-model="model.horariosTexto" placeholder="Mensaje" class="w-100" size="small" />
                                <span class="f-13 mx-2 font-italic">Este mensaje aparece en el Checkout del vendedor y del cliente</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <horario-laboral class="pl-5 ml-4" /> -->
            </div>

            <div class="bg-white br-12 pt-4 pb-3 pb-4 px-3 mt-3">
                <div class="row mx-0">
                    <div class="col-auto" />
                    <div class="col-auto" />
                    <div class="col-auto text-general f-17 f-500">
                        Ubicación
                    </div>
                </div>
                <div class="row mx-0 mt-3">
                    <div class="col-auto" />
                    <div class="col-auto" />
                    <div class="col-auto" />
                    <div class="col-auto" />
                    <div class="col-10">
                        <div class="row mx-0 mt-2">
                            <div class="col-4 px-1">
                                <ValidationProvider v-slot="{errors}" rules="required" name="país">
                                    <p class="ml-2 text-general f-14 pl-2">País</p>
                                    <el-select v-model="model.idm_pais" size="small" class="w-100" filterable @change="consultarEstados">
                                        <el-option
                                        v-for="item in paises"
                                        :key="item.id"
                                        :label="item.nombre"
                                        :value="item.id"
                                        />
                                    </el-select>
                                    <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                </ValidationProvider>
                            </div>
                            <div class="col-4 px-2">
                                <ValidationProvider v-slot="{errors}" rules="required" name="estado">
                                    <p class="ml-2 text-general f-14 pl-2">Estado</p>
                                    <el-select v-model="model.idm_estado" size="small" class="w-100" filterable @change="consultarCiudades">
                                        <el-option
                                        v-for="item in estados"
                                        :key="item.id"
                                        :label="item.nombre"
                                        :value="item.id"
                                        />
                                    </el-select>
                                    <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                </ValidationProvider>
                            </div>
                            <div class="col-4 px-1">
                                <ValidationProvider v-slot="{errors}" rules="required" name="ciudad">
                                    <p class="ml-2 text-general f-14 pl-2">Ciudad</p>
                                    <div class="row mx-0 align-items-center">
                                        <el-select v-model="model.idm_ciudad" size="small" class="col px-0" filterable>
                                            <el-option
                                            v-for="item in ciudades"
                                            :key="item.id"
                                            :label="item.nombre"
                                            :value="item.id"
                                            />
                                        </el-select>
                                        <!-- <i class="icon-pencil f-18 cr-pointer" @click="disabled = !disabled" /> -->
                                    </div>
                                    <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="row mx-0 my-3">
                            <ValidationProvider v-slot="{errors}" rules="required|max:120" tag="div" class="col px-0" name="dirección">
                                <label class="pl-3 text-general f-14"> Dirección </label>
                                <el-input v-model="model.direccion" size="small" class="w-100" maxlength="120" show-word-limit placeholder="Dirección" @change="buscarDireccion" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="row mx-0 my-3">
                            <div class="col 12 px-0">
                                <mapa ref="mapaPeq" class="br-12" :coordenadas="coordenadas" icono="cedis-point-map.svg" :buscar="getDireccionCon" @actualizar="actualizarCoordenadas" />
                                <p class="f-13 mt-2 text-gris2 cr-pointer" @click="abrirModalMapa()">
                                    Haga click aquí, para seleccionar la ubicación de su tienda
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ValidationObserver>
        <mapa-google-modal
        ref="modalMapaGoogle"
        busqueda
        postal
        icono-central="/img/icons/cedis-point-map.svg"
        :buscar="getDireccionCon"
        :coordenadas-entrada="coordenadas"
        @actualizar="actualizarCoordenadasCiudad"
        />
    </section>
</template>
<script>
import { mapGetters } from 'vuex'
import Localizaciones from "~/services/proyectos/localizaciones";
import Cedis from "../../services/cedis/index"
import CedisAll from "~/services/cedis";

export default {
    components: {
        //horarioLaboral: () => import('./components/horarioLaboral')
    },
    data(){
        return{
            disabled: true,
            paises: [],
            estados: [],
            formaPago: false,
            ciudades: [],
            slimOptions: {
                ratio: '1:1',
                label:'Subir imagen',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
            },
            model: {
                estado: true,
                cedis_inventario: false,
                cedis_catalogo: false,
                nombre: '',
                direccion: '',
                impuestos:0,
                telefono: '',
                idm_moneda: null,
                descripcion: '',
                latitud: '',
                longitud: '',
                id_user: null,
                idm_pais: null,
                idm_estado: null,
                id_cedis_stock: null,
                id_cedis_catalogo: null,
                idm_ciudad: null,
                operacion_logistica: '1',
                stock_agotado: '1',
                gaming: '0',
                horas_autoconfirmacion: null,
                dias_reclamo: null,
                pago_efectivo: 1,
                pago_credito: 1,
                formaPagos: [],
                horariosUso: 1,
                horariosTexto: null,
                descuentoClientes: 0,
                descuentoLeecheros: 2,
                idClasificacion: null,
            },
            coordenadas: {
                lat: 7.080734,
                lng: -73.147827,
            },
            resposanbles: [],
            cedis: [],
            listaClasificacion: [],
        }
    },
    computed: {
        ...mapGetters({
            monedas:'cedis/monedas',
        }),
        getDireccionCon(){
            const { ciudad = '' } = this.ciudades.find(x => x.id === this.model.idm_ciudad) || {};
            const { nombre: pais = '' } = this.paises.find(x => x.id === this.model.idm_pais) || {};
            return [this.model.direccion, ciudad, pais].filter(el => el).join(',')
        },
    },
    created(){
        this.get_cedis()
        this.get_responsables()
        this.getFormaPagos();
        this.getClasificaciones();
        this.consultarPaises()
        this.limpiar()

        this.$refs.mapaPeq.setPosition()
    },
    methods: {
        toggle(){
            this.get_cedis()
            this.get_responsables()
            this.consultarPaises()
            this.limpiar()
            this.$refs.mapaPeq.setPosition()
            this.$refs.modalCrearCedis.toggle();
        },
        abrirModalMapa(){
            this.$refs.modalMapaGoogle.toggle()
        },
        actualizarCoordenadasCiudad({ latitud, longitud }){
            this.model.latitud = latitud
            this.model.longitud = longitud
            this.coordenadas.lat = +this.model.latitud
            this.coordenadas.lng = +this.model.longitud
            this.$refs.mapaPeq.setPosition()
        },
        async crear_cedis(){
            try {
                const valid = await this.$refs.validacion.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }
                const archivo = this.$refs.foto_cedis.instanciaCrop.dataBase64.output
                this.model.foto = archivo.image

                const {data} = await Cedis.crear_cedis(this.model)
                this.notificacion('Mensaje', 'Centro de distribución creado exitosamente', 'success')
                this.$emit('listar')
                this.limpiar()
                this.$store.dispatch('cedis/action_get_cedis')
                this.$router.back();
            } catch (e){
                this.error_catch(e)
            }
        },
        async get_responsables(){
            try {
                const {data} = await Cedis.get_responsables()
                this.resposanbles = data
            } catch (e){
                this.error_catch(e)
            }
        },
        async get_cedis(){
            try {
                const {data} = await CedisAll.get_cedis()
                this.cedis = data.cedis
            } catch (e){
                this.error_catch(e)
            }
        },
        limpiar(){
            this.model = {
                estado: true,
                cedis_inventario: false,
                cedis_catalogo: false,
                nombre: '',
                direccion: '',
                impuestos:0,
                telefono: '',
                idm_moneda: null,
                descripcion: '',
                latitud: 7.080734,
                longitud:-73.147827,
                id_user: null,
                idm_pais: null,
                idm_estado: null,
                id_cedis_stock: null,
                id_cedis_catalogo: null,
                idm_ciudad: null,
                operacion_logistica: '1',
                stock_agotado: '1',
                gaming: '0',
                pago_efectivo: 1,
                pago_credito: 1,
                formaPagos: [],
                horariosUso: 1,
                horariosTexto: null,
                descuentoClientes: 0,
                descuentoLeecheros: 2,
                idClasificacion: null,
            }
            this.$refs.foto_cedis.instanciaCrop.remove()
        },
        async consultarPaises(){
            try {
                const {data} = await Localizaciones.consultarPaises(this.$usuario.proyecto.id)
                this.paises = data.data
            } catch (e){
                this.error_catch(e)
            }
        },
        async consultarEstados(){
            try {
                this.model.idm_estado = null
                this.model.idm_ciudad = null
                this.estados = []
                this.ciudades = []
                const {data} = await Localizaciones.consultarEstados({
                    proyecto: this.$usuario.proyecto.id,
                    pais: this.model.idm_pais
                })

                this.estados = data.data
            } catch (e){
                this.error_catch(e)
            }
        },
        async consultarCiudades(){
            try {
                this.model.idm_ciudad = null
                this.ciudades = []
                const {data} = await Localizaciones.consultarCiudades({
                    proyecto: this.$usuario.proyecto.id,
                    estado: this.model.idm_estado
                })
                this.model.idm_ciudad = null
                this.ciudades = data.data
            } catch (e){
                this.error_catch(e)
            }
        },
        actualizarCoordenadas({ latitud, longitud }){
            this.model.latitud = latitud
            this.model.longitud = longitud
        },
        buscarDireccion(){
            this.$refs.mapaPeq.accionBuscar();
        },
        async getFormaPagos(){
            try {
                const {data} = await Cedis.getFormaPagos();
                this.model.formaPagos = data.formaPagos;
            } catch(e){
                this.error_catch(e)
            }
        },
        changeFormaPago(event, id, column){
            if(id != null)return
            if(event){
                if(column == 'pago_efectivo')return this.model.pago_efectivo = 1;
                if(column == 'pago_credito')return this.model.pago_credito = 1;
            }else{
                if(column == 'pago_efectivo')return this.model.pago_efectivo = 0;
                if(column == 'pago_credito')return this.model.pago_credito = 0;
            }
        },
        async getClasificaciones(){
            try {
                const {data} = await Cedis.getClasificaciones();
                this.listaClasificacion = data.data;
            } catch(e){
                this.error_catch(e)
            }
        },
    }
}
</script>
