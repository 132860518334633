<template>
    <section class="custom-scroll pt-3 pl-3 overflow-auto" style="height:calc(100vh - 51px)">
        <div class="d-flex mx-0">
            <div class="px-0" style="width:calc(100vw - 600px)">
                <div class="row mx-0 align-items-center mb-3">
                    <div class="btn-back d-middle-center cr-pointer rounded-circle" @click="routeBack()">
                        <i class="icon-left f-14 text-white" />
                    </div>
                    <p class="col-auto f-18 text-general f-500">
                        Orden de compra No. {{ id_oc }}
                    </p>
                    <template v-if="orden_compra.estado === 1">
                        <div v-if="$can('boton_confirmar_orden_compra')" class="btn-general ml-auto px-4 f-14" @click="confirmarOrden()">
                            Confirmar Orden
                        </div>
                        <div v-if="$can('boton_gestionar_orden_compra')" class="btn-action mx-2 border" @click="irEditar()">
                            <el-tooltip content="Editar" placement="bottom" effect="light">
                                <i class="icon-pencil-outline f-20 cr-pointer" />
                            </el-tooltip>
                        </div>
                        <div v-if="$can('boton_gestionar_orden_compra')" class="btn-action border" @click="confirmarEliminarOrden()">
                            <el-tooltip content="Eliminar" placement="bottom" effect="light">
                                <i class="icon-delete-outline f-20 cr-pointer" />
                            </el-tooltip>
                        </div>
                    </template>
                </div>
                <div class="bg-white br-8 px-4 py-3">
                    <div class="row mx-0 mb-4">
                        <i class="icon-fecha-fin f-20 text-general" />
                        <div class="col-auto">
                            <p class="text-general f-15 f-300">
                                {{ orden_compra.hora_inicio | helper-fecha('dddd DD MMM YYYY') }}
                            </p>
                            <p class="text-general2 f-14">
                                {{ orden_compra.hora_inicio | helper-fecha('hh:mm a') }} - {{ orden_compra.hora_fin | helper-fecha('hh:mm a') }}
                            </p>
                        </div>
                        <i class="icon-cedis ml-5 f-18 text-general" />
                        <div class="col-auto pt-1">
                            <p class="text-general f-14">
                                {{ orden_compra.cedi }}
                            </p>
                        </div>
                        <img :src="orden_compra.proveedor_logo" class="rounded-circle ml-5 obj-cover border" width="45" height="45" />
                        <div class="col-auto">
                            <p class="text-general f-15 f-300">
                                {{ orden_compra.proveedor != null ? orden_compra.proveedor : 'No registra' }}
                            </p>
                            <p class="text-general2 f-14">
                                Proveedor
                            </p>
                        </div>
                    </div>
                    <div class="row mx-0">
                        <p class="col px-0 text-general2">
                            {{ orden_compra.observaciones }}
                        </p>
                        <template v-if="orden_compra.adjunto_bd != '' && orden_compra.adjunto_bd != null">
                            <el-tooltip content="Descargar" placement="bottom" effect="light">
                                <div class="btn-file ml-3 border px-2 br-8 d-middle text-general cr-pointer">
                                    <i class="icon-attach f-17" />
                                    <a class="ml-2 f-14" :href="orden_compra.adjunto" download="orden_compra.xlsx">
                                        Archivo adjunto
                                    </a>
                                </div>
                            </el-tooltip>
                        </template>
                        <template v-else>
                            <div class="btn-file ml-3 border px-2 br-8 d-middle text-general">
                                <i class="icon-attach f-17" />
                                <span class="ml-2 f-14">
                                    No tiene un archivo adjunto
                                </span>
                            </div>
                        </template>
                    </div>
                    <hr class="border" />
                    <div v-if="orden_compra.estado != 1" class="row mx-0">
                        <img :src="orden_compra.usuario_logo" class="rounded-circle obj-cover border" width="45" height="45" />
                        <div class="col-auto">
                            <p class="text-general f-15 f-300">
                                {{ orden_compra.actualizado_por }}
                            </p>
                            <p class="text-general2 f-14">
                                Confirmado {{ orden_compra.updated_at | helper-fecha('DD MMM YYYY') }}
                            </p>
                        </div>
                        <div class="col-auto f-14 text-general d-inline">
                            Entrega
                            <template v-if="orden_compra.estado === 1">
                                Pendiente
                            </template>
                            <template v-if="orden_compra.estado === 2">
                                Completa
                            </template>
                            <template v-if="orden_compra.estado === 3">
                                Incompleta
                            </template>
                        </div>
                    </div>
                </div>
                <div class="row mx-0 bg-white py-3 br-t-8 mt-4">
                    <div class="col-auto f-18 text-general f-500">
                        Registros de importación de inventario
                    </div>
                    <div v-if="$can('boton_productos_importar_stock')" class="btn-general px-4 f-14 ml-auto mr-2" @click="subirArchivo()">
                        Importar inventario
                    </div>
                    <el-tooltip placement="bottom" content="Descargar plantilla de ejemplo" effect="light">
                        <div class="btn-action border mr-2">
                            <vue-excel-xlsx
                            class="btn"
                            :data="examplePlantilla"
                            :columns="fieldsPlantilla"
                            :filename="'plantilla_stock'"
                            :sheetname="'sheetname'"
                            >
                                <i class="icon-descargar f-18 text-general" />
                            </vue-excel-xlsx>
                        </div>
                    </el-tooltip>
                </div>
                <div class="row mx-0">
                    <div class="col-12 px-0">
                        <el-table
                        :data="importaciones"
                        header-row-class-name="text-general f-16"
                        class="cr-pointer"
                        style="width: 100%"
                        @row-click="irDetalleImportacion"
                        >
                            <el-table-column
                            class-name="text-center"
                            label="Fecha"
                            min-width="150"
                            >
                                <template slot-scope="scope">
                                    <span>{{ scope.row.created_at | helper-fecha('DD MMM YYYY') }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                            class-name="text-center"
                            prop="productos_numero"
                            label="Importados"
                            min-width="150"
                            />
                            <el-table-column
                            class-name="text-center"
                            prop="productos_cantidad"
                            label="Unidades"
                            min-width="150"
                            />
                            <el-table-column
                            class-name="text-center"
                            label="Valor inventario"
                            min-width="180"
                            >
                                <template slot-scope="scope">
                                    <span class="ml-1">{{ separadorNumero(scope.row.productos_valor) }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                            prop="proveedor"
                            label="Proveedor"
                            min-width="160"
                            />
                            <el-table-column
                            class-name="text-center"
                            label="Estado"
                            min-width="130"
                            >
                                <template slot-scope="scope">
                                    {{ definirEstadoImportacion(scope.row.estado) }}
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
            <div class="col px-0 ml-3">
                <div class="bg-general3 f-500 px-4 d-middle text-white" style="height:44px;border-radius: 8px 0px 0px 0px">
                    Comentarios
                </div>
                <div class=" bg-white" style="height:calc(100vh - 126px)">
                    <cargando v-if="cargando" />
                    <div class="custom-scroll overflow-auto pt-4" style="height:calc(100% - 41px)">
                        <template v-if="comentarios.length > 0">
                            <div v-for="(item,x) in comentarios" :key="x" class="row mx-0 justify-content-end pr-4 mt-3">
                                <template v-if="item.tipo === 1">
                                    <div class="col-10 burbuja-chat br-6 px-2 py-2">
                                        <p class="text-general f-14 f-500">
                                            {{ item.usuario }}
                                        </p>
                                        <p class="text-general2 text-right f-14 mt-2">
                                            {{ item.comentario }}
                                        </p>
                                        <p class="text-general2 text-right f-14 mt-2">
                                            {{ item.created_at | helper-fecha('DD-MMM-YYYY - HH:mm a') }}
                                        </p>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="col-10 burbuja-chat br-6 px-2 py-2">
                                        <p class="text-general f-14 f-500">
                                            {{ item.usuario }}
                                        </p>
                                        <p v-if="false" class="text-general f-15 my-2">
                                            {{ item.comentario }}
                                        </p>
                                        <template v-if="item.tipo_extension === 'img'">
                                            <img :src="item.archivo" class="br-4 my-2 cr-pointer" style="width:100%;max-height:160px;object-fit:cover;" @click="verArchivoPdf(item.archivo)" />
                                        </template>
                                        <template v-else-if="item.tipo_extension === 'doc'">
                                            <div class="container-file">
                                                <div class="row mx-0 h-100 align-items-center">
                                                    <i class="icon-attach f-17" />
                                                    <div class="col pr-0 pl-2">
                                                        <a v-if="item.extension.toLowerCase() === 'pdf'" class="ml-2 f-12" style="word-break:break-all;" @click="verArchivoPdf(item.archivo)"><small>{{ item.comentario }}</small></a>
                                                        <a v-else class="ml-2 f-12" :href="item.archivo" download="archivo" style="word-break:break-all;">
                                                            {{ item.comentario }}
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <p class="text-general f-15 my-2">No se pudo leer correctamente el archivo adjunto.</p>
                                        </template>
                                        <p class="text-general2 text-right f-14 mt-2">
                                            {{ item.fecha | helper-fecha('yyyy/MM/DD - hh:mm a') }}
                                        </p>
                                    </div>
                                </template>
                            </div>
                        </template>
                        <template v-else>
                            <div class="row mx-0 justify-content-end pr-4">
                                <div class="col-12 text-center br-6 px-2">
                                    <p class="text-general2">
                                        Sin comentarios
                                    </p>
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="row mx-0 align-items-center">
                        <div class="col pl-3">
                            <el-input v-model="comentario" placeholder="Digite su comentario..." class="w-100 br-20" />
                        </div>
                        <div class="col-auto pl-0 pr-3">
                            <el-upload
                            ref="upload"
                            action="https://jsonplaceholder.typicode.com/posts/"
                            :on-change="changeFile"
                            :on-remove="removeFile"
                            :auto-upload="false"
                            :multiple="false"
                            :limit="1"
                            :show-file-list="false"
                            >
                                <i class="icon-attach f-17 cr-pointer" />
                            </el-upload>
                        </div>
                        <div class="col-auto pl-0 pr-3">
                            <i class="icon-send f-20 text-general cr-pointer" @click="validarComentario()" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal ref="modalConfirmarOrden" titulo="Confirmar orden de compra" no-aceptar adicional="Confirmar" @adicional="irConfirmarOrdenCompra()">
            <div class="row mx-0 justify-center">
                <p class="col-12 text-general text-center f-300 mb-3">
                    ¿Desea confirmar orden de compra?
                </p>
                <div class="col-auto px-0 d-middle">
                    <input v-model="tipo" :value="2" type="radio" class="option-input black radio" />
                    <span class="ml-1">Entrega completa</span>
                </div>
                <div class="col-auto px-0 d-middle ml-3">
                    <input v-model="tipo" :value="3" type="radio" class="option-input black radio" />
                    <span class="ml-1">Entrega incompleta</span>
                </div>
            </div>
        </modal>
        <modal-eliminar ref="modalEliminar" titulo="Eliminar orden de compra" mensaje="¿Desea eliminar esta orden de compra?" @eliminar="eliminarOrden" />
        <modal-editar-compra ref="editarCompra" @consultar-orden="getOrdenCompra" />
        <modal-detalle-anulado ref="modalDetalleAnulado" />
        <modal-subir-stock ref="modalImportarStock" />
    </section>
</template>

<script>
import Ordenes from "~/services/cedis/cedis_ordenes_compras"

import VueExcelXlsx from "vue-excel-xlsx";
import excel from 'vue-excel-export'
import Vue from 'vue'
Vue.use(excel)
Vue.use(VueExcelXlsx)

export default {
    components: {
        modalEditarCompra: () => import('./partials/modalEditarCompra'),
        modalDetalleAnulado: () => import('../../partials/modalDetalleAnulado'),
        modalSubirStock: () => import('~/pages/cedis/partials/modalImportarStock')
    },
    data(){
        return {
            cargando: false,
            comentario: '',
            tipo_comentario: 1,
            file: '',
            comentarios: [],
            importaciones: [],
            orden_compra: {
                hora_inicio: '',
                hora_fin: '',
                hora:  '',
                id_cedis: '',
                cedi: '',
                id_proveedor: '',
                proveedor: '',
                proveedor_logo: '',
                observaciones: '',
                adjunto: '',
                adjunto_bd: '',
                actualizado_por: '',
                usuario_logo: '',
                estado: '',
            },
            tipo: 2,
            examplePlantilla:[{barcode:'',cantidad:'',valor:'',proveedor:'',comentario:''}],
        }
    },
    computed: {
        id_oc(){
            return this.$route.params.id_oc
        },
        idCedis(){
            return Number(this.$route.params.id_cedis)
        },
        fieldsPlantilla(){
            return[
                {
                    label: "barcode",
                    field: "barcode",
                },
                {
                    label: "cantidad",
                    field: "cantidad",
                },
                {
                    label: "valor",
                    field: "valor",
                },
                {
                    label: "proveedor",
                    field: "proveedor",
                },
                {
                    label: "comentario",
                    field: "comentario",
                },
            ]
        },
    },
    created(){
        this.getOrdenCompra();
    },
    methods: {
        async getOrdenCompra(){
            try {
                const {data} = await Ordenes.getOrdenCompra(this.id_oc);
                this.asignarVariables(data.orden);
                this.comentarios = data.comentarios.comentarios;
                this.importaciones = data.importaciones;
            } catch (e){
                this.error_catch(e)
            }
        },
        confirmarOrden(){
            this.$refs.modalConfirmarOrden.toggle();
        },
        async irConfirmarOrdenCompra(){
            try {
                let form = {
                    estado: this.tipo
                };
                const {data} = await Ordenes.putConfirmarOrden(this.id_oc,form);
                this.asignarVariables(data.data.orden);
                this.notificacion('Mensaje', data.mensaje, data.tipo)
                this.$refs.modalConfirmarOrden.toggle();
            } catch (e){
                this.error_catch(e)
            }
        },
        irEditar(){
            this.$refs.editarCompra.toggle(this.orden_compra,this.id_oc);
        },
        confirmarEliminarOrden(){
            this.$refs.modalEliminar.toggle();
        },
        async eliminarOrden(){
            try {
                const {data} = await Ordenes.deleteOrdenCompra(this.id_oc);
                this.notificacion('Mensaje', data.mensaje, data.tipo)
                this.$refs.modalEliminar.toggle();
                this.$router.push({ name: 'admin.cedis.ordenes-compra.lista' });
            } catch (e){
                this.error_catch(e)
            }
        },
        async validarComentario(){
            if (this.comentario != '' && this.comentario != null){
                this.tipo_comentario = 1;
                this.postEnviarComentario();
                return;
            }
            if (this.file != '' && this.file != null){
                this.tipo_comentario = 2;
                this.postEnviarComentario();
                return;
            }
        },
        async postEnviarComentario(){
            this.cargando = true;
            try {
                let form = {
                    id_oc: this.id_oc,
                    tipo: this.tipo_comentario,
                    comentario: this.comentario,
                    file: this.file
                };
                form = this.crear_formData(form)
                const {data} = await Ordenes.postEnviarComentario(form);
                this.comentarios = data.comentarios.comentarios;
                this.limpiar();
            } catch (e){
                this.error_catch(e)
            }
        },
        asignarVariables(data){
            this.orden_compra.hora_inicio =  data.hora_inicio;
            this.orden_compra.hora_fin = data.hora_fin;
            this.orden_compra.hora = data.hora;
            this.orden_compra.id_cedis = data.id_cedis;
            this.orden_compra.cedi = data.cedi;
            this.orden_compra.id_proveedor = data.id_proveedor;
            this.orden_compra.proveedor = data.proveedor;
            this.orden_compra.proveedor_logo = data.proveedor_logo;
            this.orden_compra.observaciones = data.observaciones;
            this.orden_compra.adjunto = data.url;
            this.orden_compra.adjunto_bd = data.adjunto;
            this.orden_compra.actualizado_por = data.actualizado_por;
            this.orden_compra.usuario_logo = data.usuario_logo;
            this.orden_compra.estado = data.estado;
        },
        verArchivoPdf(archivo){
            window.open(archivo);
        },
        changeFile(file){
            this.file = file.raw;
            this.validarComentario();
        },
        removeFile(){
            this.file = "";
        },
        limpiar(){
            this.$refs.upload.clearFiles()
            this.comentario = "";
            this.file = "";
            this.tipo_comentario = 1;
            this.cargando = false;
        },
        irDetalleImportacion(row){
            if(parseInt(row.estado) === 1){
                this.$router.push({name:'admin.cedis.importar-inventario',params:{id_importacion:row.id}})
            }
            if(parseInt(row.estado) === 2){
                this.$router.push({name:'admin.cedis.importar-detalle',params:{id_importacion:row.id}})
            }
            if(parseInt(row.estado) === 3){
                this.$refs.modalDetalleAnulado.toggle(row)
            }
        },
        definirEstadoImportacion(estado){
            if (parseInt(estado) === 1){
                return "Pendiente";
            }
            if (parseInt(estado) === 2){
                return "Realizado";
            }
            if (parseInt(estado) === 3){
                return "Anulado";
            }
            return "----";
        },
        routeBack(){
            this.$router.push({name:'admin.cedis.ordenes-compra.lista', params:{id_cedis: this.idCedis}});
        },
        subirArchivo(){
            this.$refs.modalImportarStock.toggle();
        },
    }
}
</script>
<style lang="scss" scoped>
a{
    color: #000000;
    &:hover{
        color: #000000;
    }
}
.btn-back{
    width: 20px;
    height: 20px;
    background-color: #000000;
}
.btn-file{
    height: 32px;
}
.burbuja-chat{
    background-color: #F6F9FB;
    color: #000000;
}
.container-file{
    min-height: 32px;
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid #DBDBDB;
    border-radius: 8px;
    background-color: #FFFFFF;
}
</style>
