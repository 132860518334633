<template>
    <section>
        <div class="row mx-0 my-2">
            <div class="col d-middle">
                <div class="br-20 w-100 bg-general2 text-white text-center py-1">
                    {{ kit.productos.length }} Productos en kit
                </div>
            </div>
            <div class="col-auto">
                <el-tooltip placement="bottom" content="Añadir Producto" effect="light">
                    <div class="bg-general br-10 cr-pointer shadow px-2 py-2 text-white" @click="crearCombo(kit.id_kit)">
                        <i class="icon-plus f-18" />
                    </div>
                </el-tooltip>
            </div>
        </div>
        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 279px)">
            <div class="row mx-0 justify-center my-2">
                <div class="col-xl-auto col-lg col-sm">
                    <div v-for="(producto, index) in kit.productos" :key="index" class="d-flex">
                        <div class="col-auto px-0">
                            <img :src="producto.info.foto" width="89" height="89" class="obj-cover" />
                        </div>
                        <div class="col">
                            <p class="text-general f-14 nombre f-500">
                                {{ producto.info.nombre }}
                            </p>
                            <p class="text-general2 f-14 my-1">
                                {{ producto.info.presentacion }}
                            </p>
                            <div class="d-flex mx-0">
                                <div class="col-7 px-0">
                                    <p class="text-general f-14">
                                        <b>
                                            {{ producto.info.valor }}
                                        </b>
                                        Valor Venta
                                    </p>
                                    <p class="text-general f-14 my-1">
                                        <b>
                                            {{ producto.costo }}
                                        </b>
                                        Valor Costo
                                    </p>
                                </div>
                                <div class="col-auto px-0 d-middle">
                                    <div :id="`loading-${index}`" class="d-middle text-general">
                                        <div class="">
                                            <i v-if="producto.cantidad <= 1" class="icon-trash-empty f-20 cr-pointer" @click="eliminarProducto(producto.id_kit_producto,index)" />
                                            <i v-else class="icon-minus f-20 cr-pointer" @click="sumarRestarCantidad(index,producto,-1)" />
                                        </div>
                                        <div class="text-center" style="width:30px;">
                                            {{ producto.cantidad }}
                                        </div>
                                        <div class="">
                                            <i class="icon-plus f-18 cr-pointer" @click="sumarRestarCantidad(index,producto,1)" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mx-0 my-1">
                                <p class="col pl-0 text-general2 f-14">
                                    <b class="text-general">{{ producto.stock }}</b>
                                    Uni. Stock
                                </p>
                                <p class="col pl-0 text-general2 f-14">
                                    <b class="text-general">{{ producto.rotacion.rotacion }}</b>
                                    Rotación
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal-crear-combo ref="modalCrearCombo" @recargarProductos="recargarProductos" />
    </section>
</template>

<script>
import CedisKits from '~/services/cedis/cedis_kits'
export default {
    components: {
        modalCrearCombo: () => import('./partials/modalCrearCombo')
    },
    props:{
        kit:{
            type: Object,
            default: () => {
                return {}
            },
        }
    },
    data(){
        return {
            img_producto: 'https://www.las2orillas.co/wp-content/uploads/2021/04/biscolata-600x600.jpeg',
            cantProd: 0,

        }
    },
    methods: {
        crearCombo(id_kit){
            this.$refs.modalCrearCombo.toggle(id_kit);
        },
        async sumarRestarCantidad(index,producto,number){            
                
            try {
                let loading = this.$loading({
                    lock: true,
                    target:`#loading-${index}`});

                producto.cantidad += number
                let datos = {
                    id_kit_producto : producto.id_kit_producto,
                    cantidad: producto.cantidad
                }
                let { data } = await CedisKits.ActualizarCantidad(datos)
                loading.close()
            } catch (error){
                this.error_catch(error)
            }
        },
        async eliminarProducto(id_kit_producto,index){            
                
            try {    
                let { data } = await CedisKits.EliminarKitProducto(id_kit_producto)
                this.kit.productos.splice(index, 1);
            } catch (error){
                this.error_catch(error)
            }
        },
        recargarProductos(){            
            this.$emit('recargarProductos',this.kit.id_kit)
        }      
    }
}
</script>