<template>
    <section>
        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 232px)">
            <div class="row justify-center mx-0">
                <div class="col py-2">
                    <div class="bg-light-f5 d-flex border text-general2 py-1 br-5 px-2">
                        Valor de venta 
                        <b class="ml-auto">
                            {{ convertMoney(kit.valor, id_moneda) }}
                        </b>
                    </div>
                    <div class="bg-light-f5 d-flex border text-general2 py-1 br-5 px-2 my-2">
                        Valor teórico
                        <b class="ml-auto">
                            {{ convertMoney(kit.teorico, id_moneda) }}
                        </b>
                    </div>
                    <div class="bg-light-f5 d-flex border text-general2 py-1 br-5 px-2 my-2">
                        Costo
                        <b class="ml-auto">
                            $4.800
                        </b>
                    </div>
                </div>
                <div class="col-auto py-2">
                    <div class="bg-general3 text-white text-center br-5 px-2 py-3">
                        <p>
                            Descuento teórico
                        </p>
                        <p class="text-white f-600">
                            15%
                        </p>
                    </div>
                    <div class="bg-general2 px-2 text-white br-5 my-2 text-center">
                        <p>
                            Utilidad <b>20 %</b>
                        </p>
                    </div>
                </div>
            </div>
            <div class="row mx-0 px-2 my-2">
                <div class="col-6 px-0 mx-1">
                    <div class="bg-light-f5 border br-20 f-14 d-middle px-3 text-general2">
                        Ventas última semana
                        <b class="ml-auto">
                            18
                        </b>
                    </div>
                </div>
                <div class="col-4 ml-auto">
                    <div class="bg-light-f5 border px-3 br-20 f-14">
                        $ 86.400
                    </div>
                </div>
            </div>
            <div class="row mx-0">
                <div class="col-12">
                    <echart :options="chartBar" />
                </div>
            </div>
            <div class="row mx-0 px-2 my-2">
                <div class="col-6 px-0 mx-1">
                    <div class="bg-light-f5 border br-20 f-14 d-middle px-3 text-general2">
                        Ventas últ. 4 semanas
                        <b class="ml-auto">
                            18
                        </b>
                    </div>
                </div>
                <div class="col-4 ml-auto">
                    <div class="bg-light-f5 border px-3 br-20 f-14">
                        $ 86.400
                    </div>
                </div>
            </div>
            <div class="row mx-0">
                <div class="col-12">
                    <echart :options="ventasUltSemanas" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
let colorGeneral = getComputedStyle(document.documentElement).getPropertyValue('--color-db')

import Vue from 'vue'
import echarts from 'echarts'

import {mapGetters} from 'vuex'

Vue.component('echarts',echarts);

export default {
    props:{
        kit:{
            type: Object,
            default: () => {
                return {}
            },
        }
    },
    data(){
        return {
            chartBar: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: { type: 'shadow' },
                },
                xAxis: {
                    type: 'category',
                    axisLine: {
                        lineStyle: {
                            color: '#DBDBDB',
                            width: 2
                        }
                    },
                    data: ['S', 'D', 'L', 'M', 'M', 'J', 'V' ],
                },
                yAxis: {
                    show:'false',
                    axisLine: {
                        lineStyle: {
                            color: '#DBDBDB',
                            width: 2
                        }
                    },
                    splitLine: {
                        lineStyle: {
                            color: "#DBDBDB",
                        }
                    },
                },
                series: [
                    {
                        type: 'bar',
                        barMaxWidth: 30,
                        data: [120, 132, 101, 134, 90, 230, 210],
                        itemStyle: {
                            normal: {
                                color: colorGeneral
                            },
                        },
                    },
                ]
            },
            ventasUltSemanas: {
                xAxis: {
                    data: ['31', '6', '15', '24', '14 - 25', '7 - 5', '8'],
                    axisLine: {
                        lineStyle: {
                            color: '#DBDBDB',
                            width: 2
                        }
                    },
                    axisLabel: {
                        color: '#DBDBDB',
                        fontSize: 14
                    }
                    
                },
                yAxis: {
                    nameTextStyle: {
                        color: '#fff',
                        fontSize: 16
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#DBDBDB',
                            width: 2
                        }
                    },
                    axisLabel: {
                        color: '#DBDBDB',
                        fontSize: 16
                    },
                    splitLine: {
                        lineStyle: {
                            color: "#DBDBDB",
                        }
                    },
                    interval:500,

                },
                series: [
                    {
                        type: 'bar',
                        barWidth: 50,
                        itemStyle:{
                            normal:{
                                color: '#DBDBDB'
                            }
                        },
                        data: [254, 3254, 1654, 2454, 4757, 2011, 1211]
                    }
                ]
            }
        }
    },
    computed:{
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
            id_moneda:'cedis/id_moneda',
            cedis:'cedis/cedis',
        }),
    },
}
</script>