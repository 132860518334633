<template>
    <section>
        <div class="bg-white position-relative">
            <cargando v-if="cargando" />
            <div class="bg-white br-t-12">
                <el-table
                :data="categorias"
                height="calc(100vh - 199px)"
                header-row-class-name="f-400"
                style="width: 100%"
                >
                    <div :style="`${!info_cedis.catalogo_padre ? 'pointer-events:none' : '' }`">
                        <el-table-column width="200" fixed>
                            <template slot="header">
                                <p class="text-general f-16 f-600">
                                    Categorías
                                </p>
                            </template>
                            <template slot-scope="scope">
                                <p class="text-general f-16">
                                    {{ scope.row.nombre }}
                                </p>
                                <p class="mt-2">
                                    <i class="icon-package-variant-closed f-22 text-general" />
                                    <span class="text-general f-15 ml-1">
                                        {{ scope.row.contador_total }}
                                    </span>
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column width="180">
                            <template slot="header">
                                <p class="text-general f-15 text-center lh-18">
                                    Vender <br />
                                    sin stock
                                </p>
                            </template>
                            <template slot-scope="scope">
                                <div class="row mx-0 justify-center">
                                    <el-checkbox v-model="scope.row.venta_sin_stock" :disabled="!info_cedis.catalogo_padre" class="check-dark mr-1" @change="actualizarCc(scope.row.id_registro,'venta_sin_stock',scope.row.venta_sin_stock)" />
                                    <el-tooltip class="item" effect="light" content="Aplicar esta configuración a todos los productos del cedi " placement="bottom">
                                        <i v-if="info_cedis.catalogo_padre" class="icon-historial f-17 text-orange cr-pointer" @click="abrirModalConfirmarActualizacion(scope.row.id_categoria,'venta_sin_stock',scope.row.venta_sin_stock)" />
                                    </el-tooltip>
                                </div>
                                <p class="mt-2 text-center">
                                    <i class="icon-package-variant-closed f-22 text-general" />
                                    <span class="text-general f-15 ml-1">
                                        {{ scope.row.contador_venta_sin_stock }}
                                    </span>
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column min-width="200">
                            <template slot="header">
                                <p class="text-general f-15 text-center lh-18">
                                    Stock mínimo <br />
                                    del cedi
                                </p>
                            </template>
                            <template slot-scope="scope">
                                <div class="row mx-0 justify-center align-items-center">
                                    <div class="col-10">
                                        <el-input v-model="scope.row.stock_minimo_cedis" :disabled="!info_cedis.catalogo_padre" size="small" class="w-100" max="32767" @change="actualizarCc(scope.row.id_registro,'stock_minimo_cedis',scope.row.stock_minimo_cedis)" />
                                    </div>
                                    <el-tooltip class="item" effect="light" content="Aplicar esta configuración a todos los productos del cedi " placement="bottom">
                                        <i v-if="info_cedis.catalogo_padre" class="icon-historial f-17 text-orange cr-pointer" @click="abrirModalConfirmarActualizacion(scope.row.id_categoria,'stock_minimo_cedis',scope.row.stock_minimo_cedis)" />
                                    </el-tooltip>
                                </div>
                                <div class="row mx-0">
                                    <div class="col-10">
                                        <p class="mt-2 text-left">
                                            <i class="icon-package-variant-closed f-22 text-general" />
                                            <span class="text-general f-15 ml-1">
                                                {{ scope.row.contador_stock_minimo_cedis }}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column min-width="200">
                            <template slot="header">
                                <p class="text-general f-15 text-center lh-18">
                                    Stock mínimo <br />
                                    del {{ $config.vendedor }}
                                </p>
                            </template>
                            <template slot-scope="scope">
                                <div class="row mx-0 justify-center align-items-center">
                                    <div class="col-10">
                                        <el-input v-model="scope.row.stock_minimo_tienda" :disabled="!info_cedis.catalogo_padre" size="small" max="32767" class="w-100" @change="actualizarCc(scope.row.id_registro,'stock_minimo_tienda',scope.row.stock_minimo_tienda)" />
                                    </div>
                                    <el-tooltip class="item" effect="light" content="Aplicar esta configuración a todos los productos del cedi " placement="bottom">
                                        <i v-if="info_cedis.catalogo_padre" class="icon-historial f-17 text-orange cr-pointer" @click="abrirModalConfirmarActualizacion(scope.row.id_categoria,'stock_minimo_tienda',scope.row.stock_minimo_tienda)" />
                                    </el-tooltip>
                                </div>
                                <div class="row mx-0">
                                    <div class="col-10">
                                        <p class="mt-2 text-left">
                                            <i class="icon-package-variant-closed f-22 text-general" />
                                            <span class="text-general f-15 ml-1">
                                                {{ scope.row.contador_stock_minimo_tienda }}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column min-width="200">
                            <template slot="header">
                                <p class="text-general f-15 text-center lh-18">
                                    Compra mínima <br />
                                    permitida
                                </p>
                            </template>
                            <template slot-scope="scope">
                                <div class="row mx-0 justify-center align-items-center">
                                    <div class="col-10">
                                        <el-input v-model="scope.row.minima_compra_permitida" :disabled="!info_cedis.catalogo_padre" size="small" max="8388607" class="w-100" @change="actualizarCc(scope.row.id_registro,'minima_compra_permitida',scope.row.minima_compra_permitida)" />
                                    </div>
                                    <el-tooltip class="item" effect="light" content="Aplicar esta configuración a todos los productos del cedi " placement="bottom">
                                        <i v-if="info_cedis.catalogo_padre" class="icon-historial f-17 text-orange cr-pointer" @click="abrirModalConfirmarActualizacion(scope.row.id_categoria,'minima_compra_permitida',scope.row.minima_compra_permitida)" />
                                    </el-tooltip>
                                </div>
                                <div class="row mx-0">
                                    <div class="col-10">
                                        <p class="mt-2 text-left">
                                            <i class="icon-package-variant-closed f-22 text-general" />
                                            <span class="text-general f-15 ml-1">
                                                {{ scope.row.contador_minima_compra_permitida }}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column min-width="200">
                            <template slot="header">
                                <p class="text-general f-15 text-center lh-18">
                                    Compra máxima <br />
                                    permitida
                                </p>
                            </template>
                            <template slot-scope="scope">
                                <div class="row mx-0 justify-center align-items-center">
                                    <div class="col-10">
                                        <el-input v-model="scope.row.maxima_compra_permitida" :disabled="!info_cedis.catalogo_padre" size="small" max="8388607" class="w-100" @change="actualizarCc(scope.row.id_registro,'maxima_compra_permitida',scope.row.maxima_compra_permitida)" />
                                    </div>
                                    <el-tooltip class="item" effect="light" content="Aplicar esta configuración a todos los productos del cedi " placement="bottom">
                                        <i v-if="info_cedis.catalogo_padre" class="icon-historial f-17 text-orange cr-pointer" @click="abrirModalConfirmarActualizacion(scope.row.id_categoria,'maxima_compra_permitida',scope.row.maxima_compra_permitida)" />
                                    </el-tooltip>
                                </div>
                                <div class="row mx-0">
                                    <div class="col-10">
                                        <p class="mt-2 text-left">
                                            <i class="icon-package-variant-closed f-22 text-general" />
                                            <span class="text-general f-15 ml-1">
                                                {{ scope.row.contador_maxima_compra_permitida }}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column min-width="200">
                            <template slot="header">
                                <p class="text-general f-15 text-center lh-18">
                                    Aplicar <br />
                                    impuesto
                                </p>
                            </template>
                            <template slot-scope="scope">
                                <div class="row mx-0 justify-center align-items-center">
                                    <div class="col-10">
                                        <el-select v-model="scope.row.id_impuesto" class="w-100" :disabled="!info_cedis.catalogo_padre" size="small" @change="actualizarCc(scope.row.id_registro,'id_impuesto',scope.row.id_impuesto)">
                                            <el-option
                                            v-for="(imp,index) in impuestos"
                                            :key="index"
                                            :label="imp.nombre"
                                            :value="imp.id"
                                            />
                                        </el-select>
                                    </div>
                                    <el-tooltip class="item" effect="light" content="Aplicar esta configuración a todos los productos del cedi " placement="bottom">
                                        <i v-if="info_cedis.catalogo_padre" class="icon-historial f-17 text-orange cr-pointer" @click="abrirModalConfirmarActualizacion(scope.row.id_categoria,'id_impuesto',scope.row.id_impuesto)" />
                                    </el-tooltip>
                                </div>
                                <div class="row mx-0">
                                    <div class="col-10">
                                        <p class="mt-2 text-left">
                                            <i class="icon-package-variant-closed f-22 text-general" />
                                            <span class="text-general f-15 ml-1">
                                                {{ scope.row.contador_id_impuesto }}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column min-width="200">
                            <template slot="header">
                                <p class="text-general f-15 text-center lh-18">
                                    Inventario del <br />
                                    proveedor
                                </p>
                            </template>
                            <template slot-scope="scope">
                                <div class="row mx-0 justify-center align-items-center">
                                    <el-checkbox v-model="scope.row.inventario_proveedor" :disabled="!info_cedis.catalogo_padre" size="small" class="check-dark mr-1" @change="actualizarCc(scope.row.id_registro,'inventario_proveedor',scope.row.inventario_proveedor)" />
                                    <el-tooltip class="item" effect="light" content="Aplicar esta configuración a todos los productos del cedi " placement="bottom">
                                        <i v-if="info_cedis.catalogo_padre" class="icon-historial f-17 text-orange cr-pointer" @click="abrirModalConfirmarActualizacion(scope.row.id_categoria,'inventario_proveedor',scope.row.inventario_proveedor)" />
                                    </el-tooltip>
                                </div>
                                <p class="mt-2 text-center">
                                    <i class="icon-package-variant-closed f-22 text-general" />
                                    <span class="text-general f-15 ml-1">
                                        {{ scope.row.contador_inventario_proveedor }}
                                    </span>
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column
                        width="250"
                        >
                            <template slot="header">
                                <p class="text-general f-15 text-center lh-18">
                                    Proveedor
                                </p>
                            </template>
                            <template slot-scope="scope">
                                <div class="row mx-0 justify-center align-items-center">
                                    <div class="col-10">
                                        <el-select v-model="scope.row.id_proveedor" :disabled="!info_cedis.catalogo_padre" filterable size="small" class="w-100" @change="actualizarCc(scope.row.id_registro,'id_proveedor',scope.row.id_proveedor)">
                                            <el-option
                                            v-for="(pro,index) in proveedores"
                                            :key="index"
                                            :label="pro.nombre"
                                            :value="pro.id"
                                            />
                                        </el-select>
                                    </div>
                                    <el-tooltip class="item" effect="light" content="Aplicar esta configuración a todos los productos del cedi " placement="bottom">
                                        <i v-if="info_cedis.catalogo_padre" class="icon-historial f-17 text-orange cr-pointer" @click="abrirModalConfirmarActualizacion(scope.row.id_categoria,'id_proveedor',scope.row.id_proveedor)" />
                                    </el-tooltip>
                                </div>
                                <div class="row mx-0">
                                    <div class="col-10">
                                        <p class="mt-2 text-left">
                                            <i class="icon-package-variant-closed f-22 text-general" />
                                            <span class="text-general f-15 ml-1">
                                                {{ scope.row.contador_id_proveedor }}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                    </div>
                </el-table>
            </div>
            <modal ref="modalConfirmarActualizacion" titulo="Aplicar cambios" adicional="Confirmar" no-aceptar @adicional="actualizarCp()">
                <p class="text-general f-17 text-center mb-2">¿Desea aplicar esta configuración a todos los productos de la categoria de {{ confirmarActualizacion.nombre }}?</p>
                <p class="text-general f-600 f-15 text-center">{{ confirmarActualizacion.contador > 0 ? `Se actualizarán ${confirmarActualizacion.contador} productos` : `Se actualizará ${confirmarActualizacion.contador} producto` }} </p>
            </modal>
        </div>
    </section>
</template>

<script>

import CedisCategorias from '@/services/cedis/cedis_categorias'
import {mapGetters} from 'vuex'
export default {
    data(){
        return {
            cargando: false,
            confirmarActualizacion: {
                id_categoria: null,
                campo: null,
                valor: null,
                contador: 0
            },
            proveedores: [],
            impuestos: [],
            categorias: [],
            options: [{
                value: 'Option1',
                label: 'Option1'
            }, {
                value: 'Option2',
                label: 'Option2'
            }, {
                value: 'Option3',
                label: 'Option3'
            }, {
                value: 'Option4',
                label: 'Option4'
            }, {
                value: 'Option5',
                label: 'Option5'
            }],
        }
    },
    computed: {
        ...mapGetters({
            info_cedis: 'cedis/info_cedis'
        })
    },
    created(){
        this.createdCedisCategorias()
    },
    mounted(){
        this.getCedisCategorias()
        this.getProveedoresEImpuestos()
    },
    methods: {
        async createdCedisCategorias(){
            try {
                const { data } = await CedisCategorias.createdCategorias(this.$route.params.id_cedis)
            } catch (error){
                this.error_catch(error)
            }
        },
        async getProveedoresEImpuestos(){
            try {
                const { data } = await CedisCategorias.getProveedoresEImpuestos(this.$route.params.id_cedis)
                this.proveedores = data.proveedores
                this.impuestos = data.impuestos
            } catch (error){
                this.error_catch(error)
            }
        },
        async getCedisCategorias(){
            try {
                this.cargando = true
                let params = {
                    id_cedis: this.$route.params.id_cedis
                }
                const { data } = await CedisCategorias.getCedisCategorias({params})
                this.categorias = data.data.map(function(el){
                    el.venta_sin_stock = el.venta_sin_stock == 1? true : false
                    el.inventario_proveedor = el.inventario_proveedor == 1 ? true : false
                    return el
                })

            } catch (error){
                this.error_catch(error)
            } finally{
                this.cargando = false
            }
        },
        async actualizarCp(){
            try {

                let params = {
                    id_cedi: this.$route.params.id_cedis,
                    id_categoria: this.confirmarActualizacion.id_categoria,
                    campo: this.confirmarActualizacion.campo,
                    valor:this.confirmarActualizacion.valor
                }

                const { data } = await CedisCategorias.actualizarCedisProducto(params)

                this.notificacion('','Campo actualizado correctamente','success')

                let index = this.categorias.findIndex(el=>el.id_categoria==params.id_categoria)
                let campo = 'contador_'+params.campo
                this.categorias[index][campo] = this.categorias[index].contador_total

                this.$refs.modalConfirmarActualizacion.toggle()
                this.confirmarActualizacion = {}

            } catch (error){
                this.error_catch(error)
            }
        },
        async actualizarCc(idRegistro,campo,valor){
            try {
                let campos1 = ['stock_minimo_cedis','stock_minimo_tienda']
                let campos2 = ['maxima_compra_permitida']
                let campos3 = ['minima_compra_permitida']

                if((campos1.includes(campo)) && (!Number(valor) || valor>32767))  return this.notificacion('','El valor ingresado es invalido')
                if((campos2.includes(campo)) && (!Number(valor) || valor>8388607)) return this.notificacion('','El valor ingresado es invalido')
                if((campos3.includes(campo)) && (!Number(valor))) return this.notificacion('','El valor ingresado es invalido','warning')

                let params = {
                    id_registro: idRegistro,
                    campo: campo,
                    valor:valor
                }
                const { data } = await CedisCategorias.actualizarCedisCategoria(params)
                this.notificacion('','Categoría actualizada correctamente','success')

            } catch (error){
                this.error_catch(error)
            }
        },
        abrirModalConfirmarActualizacion(idCategoria,campo,valor){

            let campos1 = ['stock_minimo_cedis','stock_minimo_tienda']
            let campos2 = ['maxima_compra_permitida']
            let campos3 = ['minima_compra_permitida']

            if((campos1.includes(campo)) && (!Number(valor) || valor>32767))  return this.notificacion('','El valor ingresado es invalido')
            if((campos2.includes(campo)) && (!Number(valor) || valor>8388607)) return this.notificacion('','El valor ingresado es invalido')
            if((campos3.includes(campo)) && (!Number(valor))) return this.notificacion('','El valor ingresado es invalido','warning')

            let cat = this.categorias.find(el=>el.id_categoria==idCategoria)
            this.confirmarActualizacion = {
                id_categoria: idCategoria,
                campo: campo,
                valor: valor,
                contador: cat.contador_total,
                nombre: cat.nombre
            }

            this.$refs.modalConfirmarActualizacion.toggle()
        }

    }
}
</script>
