<template>
    <section
    class="informe-leechero custom-scroll position-relative"
    :class="$route.name == 'tendero.alianzas' ? 'box alto-comision border mx-3 br-t-12 mt-2' : $route.name == 'admin.tiendas.produccion.alianzas' ? 'height-leecheros' : '' "
    >
        <template v-if="verInfoTendero">
            <div class="row mx-0">
                <template v-if="ver_select">
                    <div class="col-12 my-3">
                        <p class="text-general f-18 f-600">
                            Visualizar informe por {{ $config.vendedor }}
                        </p>
                    </div>
                    <div class="col-12 mb-3">
                        <div class="row mx-0 py-3 px-3 bg-white br-12 text-general">
                            Para ver el informe de comisiones de un {{ $config.vendedor }}, primero es necesario selecionar el CEDIS(En select de cedis) al cual pertenece este {{ $config.vendedor }}
                        </div>
                    </div>
                    <div class="col-12 d-middle br-12">
                        <div class="col-auto text-general">
                            Seleccione un {{ $config.vendedor }}
                        </div>
                        <div class="col-4">
                            <el-select v-model="id_leechero" :placeholder="`selecciona un ${$config.vendedor}`" class="w-100 br-6" size="small" clearable filterable @change="informe_leechero">
                                <el-option
                                v-for="item in leecheros"
                                :key="item.created_by"
                                :label="`${item.nombre} - ${item.nombre_cedis}`"
                                :value="item.created_by"
                                />
                            </el-select>
                        </div>
                        <cargando v-if="ver_select && cargandoLeecheros" mini />
                    </div>
                </template>
            </div>
            <template v-if="leechero != null">
                <cargando v-if="!ver_select && cargandoLeecheros" />
                <div class="row mx-3 mb-4 bg-white br-12 pt-4">
                    <div class="col-12">
                        <p class="text-general f-18 f-600">
                            Comportamiento de la Comisión
                        </p>
                    </div>
                    <div class="col-12 px-0">
                        <div class="d-flex mx-0">
                            <echart :options="comportamiento_comision" />
                            <div class="mx-3" style="width:195px;">
                                <div class="text-general p-1" style="border-radius:12px;background-color:#F6F9FB;">
                                    <p class="f-15 my-2 text-center">
                                        Comisión Total
                                    </p>
                                    <p class="f-600 f-18 my-1 text-center tres-puntos">
                                        {{ separadorNumero(totales_meses_x_6.total) }}
                                    </p>
                                </div>
                                <div class="mt-3 text-general p-2" style="border-radius:12px;background-color:#F6F9FB;">
                                    <p class="f-15 my-1 text-center">
                                        Ultimos 6 meses
                                    </p>
                                    <p class="f-600 f-18 my-1 text-center tres-puntos">
                                        {{ separadorNumero(totales_meses_x_6.ultimos_6_meses) }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mx-3 my-4 bg-white br-12 pt-3">
                    <div class="col-12">
                        <p class="text-general f-18 f-600">
                            Relacion de Comisiones por mes
                        </p>
                    </div>
                    <div class="col-12 px-0">
                        <div class="d-flex mx-0">
                            <echart :options="relacion_X_mes" />
                            <div class="mx-3" style="width:195px;">
                                <div v-if="ver_botones" class="text-general p-1 shadow" style="border-radius:12px;background-color:#F6F9FB;">
                                    <p class="f-15 my-2 text-center">
                                        Comisión anual teórica
                                    </p>
                                    <p class="f-600 f-18 my-1 text-center tres-puntos">
                                        {{ separadorNumero(totales_meses_x_12.total) }}
                                    </p>
                                </div>
                                <div class="mt-3 text-general p-2 shadow" style="border-radius:12px;background-color:#F6F9FB;">
                                    <p class="f-15 my-1 text-center">
                                        Cantidad de pedidos
                                    </p>
                                    <p class="f-600 f-18 my-1 text-center tres-puntos">
                                        {{ agregarSeparadoresNumero(totales_meses_x_12.pedidos) }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <calendario :id-leechero="leechero" :moneda-leechero="monedaLeechero" :moneda-rol="monedaRol" :ver-botones="ver_botones" />
            </template>
        </template>
        <template v-else>
            <sinDatos icon="comision" ext="svg" mensaje="Aún no hay comisiones asignadas." />
        </template>
    </section>
</template>

<script>
import Vue from 'vue'
import echarts from 'echarts'
import { mapGetters } from 'vuex'
import Comisiones from "~/services/alianzas_leecheros";


Vue.component('echarts',echarts);
let colorGeneral = getComputedStyle(document.documentElement) .getPropertyValue('--color-general')
let colorGeneral2 = getComputedStyle(document.documentElement) .getPropertyValue('--color-db')
let colorGeneral3 = getComputedStyle(document.documentElement) .getPropertyValue('--text-general')

export default {
    components: {
        calendario: () => import('./calendario')
    },
    data(){
        let self = this
        return{
            leecheros: [],
            id_leechero: null,
            titulo_comision: 1,
            informe_seleccionado: false,
            comportamiento_comision: {
                backgroundColor: '#fff',
                tooltip: {
                    trigger: 'axis',
                    formatter: function(params){
                        return `Mes: ${params[0].value.fecha}</br>
                        Pagos ${self.separadorNumero(params[0].value.comisiones)}</br>`
                        // `Pedidos ${self.agregarSeparadoresNumero(params[0].value.pedidos)}`
                    },
                    axisPointer: {
                        label: {
                            show: true,
                            backgroundColor: '#fff',
                            color: '#556677',
                            borderColor: 'rgba(0,0,0,0)',
                            shadowColor: 'rgba(0,0,0,0)',
                            shadowOffsetY: 0
                        },
                        lineStyle: {
                            width: 1
                        }
                    },
                    backgroundColor: '#fff',
                    textStyle: {
                        color: '#5c6c7c'
                    },
                    padding: [5, 5],
                    extraCssText: 'box-shadow: 1px 0 2px 0 rgba(163,163,163,0.5)'
                },
                xAxis: [
                    {
                        type: 'category',
                        // data: ['Ener', 'Feb', 'Mar', 'abril', 'may','jun'],
                        axisLine: {
                            lineStyle: {
                                color: '#DCE2E8'
                            }
                        },
                        axisTick: {
                            show: false
                        },
                        axisLabel: {
                            interval: 0,
                            textStyle: {
                                color: '#556677'
                            },
                            fontSize: 12,
                            margin: 15
                        },
                        axisPointer: {
                            label: {
                                padding: [0, 0, 10, 0],
                                margin: 15,
                                fontSize: 12,
                                backgroundColor: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [{
                                        offset: 0,
                                        color: '#fff'
                                    }, {

                                        offset: 0.86,
                                        color: '#fff'
                                    }, {
                                        offset: 0.86,
                                        color: colorGeneral
                                    }, {
                                        offset: 1,
                                        color: colorGeneral
                                    }],
                                    global: false
                                }
                            }
                        },
                        boundaryGap: false
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        axisTick: {
                            show: false
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#DCE2E8'
                            }
                        },
                        axisLabel: {
                            textStyle: {
                                color: '#556677'
                            }
                        },
                        splitLine: {
                            show: true
                        }
                    }, {
                        type: 'value',
                        position: 'right',
                        axisTick: {
                            show: false
                        },
                        axisLabel: {
                            textStyle: {
                                color: '#556677'
                            },
                            formatter: '{value}'
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#DCE2E8'
                            }
                        },
                        splitLine: {
                            show: false
                        }
                    }
                ],
                dataset:{
                    dimensions:['fecha','comisiones'],
                    source: []
                },
                series: [
                    {
                        name: 'Crecimiento',
                        type: 'line',
                        // data: [10, 10, 14, 12, 15, 3, 7],
                        symbol: 'circle',
                        symbolSize: 10,
                        smooth: true,
                        yAxisIndex: 0,
                        showSymbol: false,
                        lineStyle: {
                            width: 5,
                            color: colorGeneral,
                            shadowColor: '#E4E4E4',
                            shadowBlur: 10,
                            shadowOffsetY: 20
                        },
                        itemStyle: {
                            normal: {
                                color: colorGeneral,
                                borderColor: colorGeneral
                            }
                        },
                        areaStyle: {
                            normal: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                    offset: 0,
                                    color: '#E5E5E5'
                                },
                                {
                                    offset: 1,
                                    color: '#F0F0F0'
                                }
                                ], false)
                            }
                        },
                    }
                ]

            },
            relacion_X_mes: {},
            totales_meses_x_6: {
                total: 0,
                ultimos_6_meses: 0,
            },
            totales_meses_x_12: {
                total: 0,
                pedidos: 0,
            },
            comisiones_tendero: 0,
            cargandoLeecheros: true
        }
    },
    computed: {
        ...mapGetters({
            id_moneda:'cedis/id_moneda',
            id_cedis:'cedis/id_cedis',
            id_cedis_navbar:'cedis/id_cedis_navbar',
            tienda: 'tiendas/info_cabecera/tienda'
        }),
        ver_select(){
            return this.$route.name != 'admin.tiendas.produccion.alianzas' && this.$route.name != 'tendero.alianzas'
        },
        ver_botones(){
            return this.$route.name != 'tendero.alianzas'
        },
        leechero(){
            let id
            switch (this.$route.name){
            case 'admin.tesoreria.alianzas':
            case 'admin.cedis.alianzas':
                id = this.id_leechero == '' ? null : this.id_leechero
                break;
            case 'admin.tiendas.produccion.alianzas':
                id = this.tienda.created_by
                break;
            case 'tendero.alianzas':
                id = this.$usuario.id
                break;
            default:
                id = null
                break;
            }
            return id
        },
        monedaLeechero(){
            let moneda
            switch (this.$route.name){
            case 'admin.tesoreria.alianzas':
            case 'admin.cedis.alianzas':
                let leechero = this.leecheros.find(el => el.created_by == this.leechero)
                moneda = leechero ? leechero.idm_moneda : null
                break;
            case 'admin.tiendas.produccion.alianzas':
                moneda = this.tienda.idm_moneda
                break;
            case 'tendero.alianzas':
                moneda = this.$tienda.idm_moneda
                break;
            default:
                moneda = null
                break;
            }
            return moneda
        },
        monedaRol(){

            let moneda = null
            if (this.$usuario.rol == 1){
                moneda = this.id_moneda
            } else {
                moneda = this.$usuario.tienda.idm_moneda
            }

            return moneda
        },
        cedis(){
            if (this.$route.params.id_cedis){
                return this.$route.params.id_cedis
            } else {
                return this.id_cedis_navbar
            }
        },
        verInfoTendero(){
            let isAdmin = /admin/.test(this.$route.name)
            return isAdmin ? true : this.totales_meses_x_12.total > 0
        }
    },
    watch: {
        id_moneda(val,oldVal){
            if (oldVal == null) return
            this.montar()
        },
        cedis(val,oldVal){
            if (oldVal == null) return
            this.id_leechero = null
            this.montar()
        },
    },
    mounted(){
        this.montarGraficas()
        this.montar()
    },
    methods: {
        montarGraficas(){
            let self = this
            let datos = {
                tooltip: {
                    position: 'top',
                    trigger: 'axis',
                    formatter: function(params){
                        return `Mes: ${params[0].value.fecha}</br>
                        Comisiones ${self.separadorNumero(params[0].value.comisiones)}</br>
                        Pedidos ${self.agregarSeparadoresNumero(params[0].value.pedidos)}`
                    }
                },
                legend: {
                    data:['Comisiones', 'Pedidos']
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: true,
                    },
                    {
                        type: 'category',
                        boundaryGap: true,
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        scale: true,
                        // name: 'Valor',
                    },
                    {
                        type: 'value',
                        scale: true,
                        show: false
                        // name: 'Pedidos',
                    }
                ],
                dataset:{
                    dimensions:['fecha','comisiones','pedidos'],
                    source:[],
                },
                series: [
                    {
                        name: 'Comisiones',
                        type: 'line',
                        // data: [10, 5, 15, 25, 35, 40, 45, 50, 55],
                        // xAxisIndex: 1,
                        symbol:'circle',
                        symbolSize: 10,
                        itemStyle: {
                            color: colorGeneral3
                        },
                    },
                    {
                        name: 'Pedidos',
                        type: 'bar',
                        yAxisIndex: 1,
                        // data: [10, 5, 15, 25, 35, 40, 45, 50, 55],
                        itemStyle: {
                            color: colorGeneral2
                        },
                    }
                ]
            }
            let datosLeechero = {
                tooltip: {
                    position: 'top',
                    trigger: 'axis',
                    formatter: function(params){
                        return `Mes: ${params[0].value.fecha}</br>
                        Comisiones pagadas ${self.separadorNumero(params[0].value.comisiones)}</br>
                        Pedidos ${self.agregarSeparadoresNumero(params[0].value.pedidos)}`
                    }
                },
                legend: {
                    data:['Comisiones pagadas', 'Pedidos']
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: true,
                    },
                    {
                        type: 'category',
                        boundaryGap: true,
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        scale: true,
                        // name: 'Valor',
                    },
                    {
                        type: 'value',
                        scale: true,
                        show: false
                        // name: 'Pedidos',
                    }
                ],
                dataset:{
                    dimensions:['fecha','comisiones','pedidos'],
                    source:[],
                },
                series: [
                    {
                        name: 'Comisiones pagadas',
                        type: 'line',
                        // data: [10, 5, 15, 25, 35, 40, 45, 50, 55],
                        // xAxisIndex: 1,
                        symbol:'circle',
                        symbolSize: 10,
                        itemStyle: {
                            color: colorGeneral3
                        },
                    },
                    {
                        name: 'Pedidos',
                        type: 'bar',
                        yAxisIndex: 1,
                        // data: [10, 5, 15, 25, 35, 40, 45, 50, 55],
                        itemStyle: {
                            color: colorGeneral2
                        },
                    }
                ]
            }

            this.relacion_X_mes = this.ver_botones ? datos : datosLeechero
        },
        async montar(){
            try {
                if(this.id_cedis === null || this.id_moneda === null){
                    setTimeout(async() => {
                        await this.lista_leecheros()
                        await this.informe_leechero()
                    }, 1000);
                }else{
                    await this.lista_leecheros()
                    await this.informe_leechero()
                }
            } catch (error){
                console.log(error)
            }finally{
                this.primeraVez = false
            }
        },
        async informe_leechero(){
            try {

                if(this.leechero == null) return
                let params = {
                    id_moneda: this.monedaRol,
                    leechero: this.leechero
                }
                this.cargandoLeecheros = true

                const {data} = await Comisiones.informe_leechero(params)
                this.comportamiento_comision.dataset.source = data.data.datos_meses_x_6
                this.totales_meses_x_6 = data.data.totales_meses_x_6
                this.relacion_X_mes.dataset.source = data.data.datos_meses_x_12
                this.totales_meses_x_12 = data.data.totales_meses_x_12
            } catch (e){
                this.error_catch(e)
            } finally{
                this.cargandoLeecheros = false
            }
        },
        async lista_leecheros(){
            try {
                const {data} = await Comisiones.lista_leecheros(this.cedis)
                this.leecheros = data
            } catch (e){
                this.error_catch(e)
            }
        },

    }
}
</script>
<style lang="scss" scoped>
.border-magenta{
    border: 1px solid #B20289;
}
.border-purple{
    border: 1px solid #9508EB;
}
.border-blue{
    border: 1px solid var(--color-general);
}
.br-12{ border-radius: 12px ; }
.alto-comision{
    overflow: auto;
    height: calc(100vh - 60px);
}
.height-leecheros{
    height: calc(100vh - 198px);
    overflow: auto;
}
.productos-comision{
    overflow: auto;
    height: calc(100vh - 230px);
}
.br-t-12{
    border-radius: 12px 12px 0px 0px;
}
.tooltip-width{
    max-width: 280px;
}
</style>
