<template>
    <section>
        <div class="d-flex mx-0 bg-white br-t-12">
            <div class="col-auto px-0">
                <div class="row mx-0 align-items-center my-1 py-2 px-3">
                    <div class="col px-0">
                        <el-input v-model="buscar" placeholder="Buscar kit" class="br-20" />
                    </div>
                </div>
                <div
                class="border-top menu-principal col overflow-auto custom-scroll px-0"
                style="height:calc(100vh - 276px);"
                >
                    <div
                    v-for="(c, idx) in categorias" :key="idx"
                    class="row mx-0 d-middle border-bottom cr-pointer"
                    :class="{'bg-whitesmoke2':activo==c.id}"
                    style="height:54px;"
                    @click="id_categoria = c.id, activo=c.id"
                    >
                        <div :class="`mx-2 px-1 h-80 br-5 ${activo==c.id?'bg-general':''}`" />
                        <p class="col py-3 px-0 tres-puntos f-15 f-500 text-general">{{ c.nombre }}</p>
                        <div class="col-auto d-middle px-0">
                            <div :class="`br-10 px-2 text-center f-17 my-auto ${activo==c.id?'bg-general3 text-white':'bg-whitesmoke'}`" style="min-width:40px;">
                                <span>{{ c.cant_productos }}</span>
                            </div>
                            <i :class="`icon-angle-right f-18 ${activo==c.id?'text-general':''}`" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col px-0 mr-2 br-t-10">
                <div class="d-flex">
                    <div class="col px-0">
                        <listado-productos :categoria="id_categoria" :kit="1" />
                        <!-- <card-kit v-for="(kit, d) in kits" :key="d" :kit="kit" :moneda="moneda" :index="d" :selected="kit_selected" @seleccionado="setKitSeleccionado"/> -->
                    </div>
                    <div v-if="!_.isEmpty(kit)" class="col border-left px-0">
                        <div class="row mx-0 border-bottom">
                            <a :class="`col py-2 text-general cr-pointer ${menu==1?'border-b-red':''}`" @click="setMenu(1)">
                                <p class="text-center">
                                    Productos
                                </p>
                            </a>
                            <a :class="`col py-2 text-general cr-pointer ${menu==2?'border-b-red':''}`" @click="setMenu(2)">
                                <p class="text-center">
                                    Registro
                                </p>
                            </a>
                            <a :class="`col py-2 text-general cr-pointer ${menu==3?'border-b-red':''}`" @click="setMenu(3)">
                                <p class="text-center">
                                    Estadísticas
                                </p>
                            </a>
                        </div>
                        <productos v-if="menu==1" :kit="kit" />
                        <registro v-if="menu==2" :kit="kit" />
                        <estadisticas v-if="menu==3" :kit="kit" />
                    </div>
                </div>
            </div>
        </div>
        <!-- Partials -->
        <!-- <modal-crear-kit ref="modalCrearKit" /> -->
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'
import Productos from '~/services/productos/productos'
export default {
    components: {
        // modalCrearKit: () => import('./partials/modalCrearKit.vue'),
        /* cardKit: () => import('../components/cardKit'), */
        productos: () => import('./productos'),
        registro: () => import('./registro'),
        estadisticas: () => import('./estadisticas'),
        listadoProductos:() => import('~/pages/productos/admin/listado-productos')
    },
    data(){
        return {
            activo: -1,
            categorias:[],
            selected:null,
            kit_selected:null,
            kit:{},
            kits:[],
            moneda:1,
            menu : 0,
            id_categoria:null,
        }
    },
    computed: {
        ...mapGetters({
            query: 'productos/productos/query',
            id_cedis:'cedis/id_cedis',
            id_moneda:'cedis/id_moneda',
            info_cedis:'cedis/info_cedis',
        }),
        buscar: {
            // getter
            get: function(){
                return this.query
            },
            // setter
            set: function(newValue){
                this.$store.commit('productos/productos/set_query',newValue)
                this.id_categoria = null
                this.activo = -1
                if(newValue.length > 2){
                    const Search = () => {
                        this.getCategorias()
                    }
                    this.delay(Search)

                }
                if(newValue.length === 0){
                    const Search = () => {
                        this.getCategorias()
                    }
                    this.delay(Search)
                }
            }
        },
    },
    watch:{
        id_cedis(val){
            if(val != null){
                this.getCategorias();
            }
        },
    },
    mounted(){
        this.buscar= '' // lanza el metodo de  lista_categorias por la computada
        this.$store.commit('productos/productos/vaciar_productos')
        this.$store.commit('productos/productos/set_id_producto', null)
        this.$store.commit('productos/productos/set_is_kit', false)
        if(!isNaN(this.id_cedi_ruta)){
            if(this.id_cedis != this.id_cedi_ruta){
                this.$store.commit('cedis/set_id_cedis', this.id_cedi_ruta)
            }
        }
    },
    methods:{
        async getCategorias(){
            try {
                this.categorias = []
                let params = {
                    query:this.query,
                    id_cedis: this.id_cedi_ruta? this.id_cedi_ruta: this.id_cedis,
                    id_moneda:this.id_moneda,
                    kit : true
                }
                const { data } = await Productos.categorias(params)
                this.categorias = data.categorias
            } catch (error){
                this.error_catch(error)
            }
        },
        actualizar_listado({categorias,producto}){
            let actual = categorias.find(o=>o.id === this.id_categoria)

            if(actual !== undefined){
                this.$store.commit('productos/productos/push_producto_nuevo', producto)
            }else{
                this.lista_categorias()
            }
        },
        async getProductosPorKit(id_kit){
            try {
                const { data } = await Productos.getProductosPorKit(this.$route.params.id_cedis,id_kit)
                this.kit = data
            } catch (error){
                this.error_catch(error)
            }
        },
        crearKit(){
            this.$refs.modalCrearKit.toggle();
        },
        setKitSeleccionado(seleccionado){
            this.kit_selected = seleccionado.index
            this.getProductosPorKit(seleccionado.kit.id_kit)
            this.setMenu(1)
        },
        setMenu(menu){
            this.menu = menu
        }
    }
}
</script>

<style lang="scss" scoped>
.menu-principal{width: 430px;}
.text-general{color: var(--text-general) !important;}
a{text-decoration: none;&:hover{color: var(--text-general) !important;}}
.br-12{border-radius: 12px !important;}
</style>
