<template>
    <section>
        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 258px)">
            <ValidationObserver ref="formGuardarKit">
                <div class="row mx-0 justify-center py-2">
                    <div class="col-auto px-0">
                        <slim-cropper ref="foto_kit_registro" :options="slimOptions" class="border br-10" style="height:160px;width:160px;background:#F8F9FF;">
                            <div slot="label" class="">
                                <img :src="kit.foto" alt="" />
                            </div>
                        </slim-cropper>
                    </div>
                    <div class="col-6 text-general">
                        <p class="f-600 text-general">
                            Creado:
                        </p>
                        <p>
                            {{kit.creador}}
                        </p>
                        <p>
                            {{kit.creado_en | helper-fecha('DD MMM YYYY - HH:mm a')}}
                        </p>
                        <p class="f-600 text-general mt-3">
                            Actualizado:
                        </p>
                        <p>
                            {{kit.editor}}
                        </p>
                        <p>
                            {{kit.actualizado_en | helper-fecha('DD MMM YYYY - HH:mm a')}}
                        </p>
                    </div>
                </div>
                <div class="row mx-0 justify-center">
                    <div class="col-11 my-2">
                        <p class="text-general pl-3 f-14">
                            Nombre
                        </p>
                        <ValidationProvider v-slot="{ errors }" rules="required" name="nombre">
                            <el-input v-model="kit.nombre" class="w-100" />
                            <span class="text-danger f-11">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                    <div class="col-11 my-2">
                        <p class="text-general pl-3 f-14">
                            Precio de venta
                        </p>
                        <ValidationProvider v-slot="{ errors }" rules="required|numeric" name="precio de venta">
                            <input-miles v-model="kit.valor" class="w-100" maxlength="8" />
                            <span class="text-danger f-11">{{ errors[0] }}</span>
                        </ValidationProvider>
                        
                    </div>
                    <div class="col-11 my-2">
                        <p class="text-general pl-3 f-14">
                            Precio teórico
                        </p>
                        <ValidationProvider v-slot="{ errors }" rules="required|numeric" name="precio teórico">
                            <input-miles v-model="kit.valor_teorico" class="w-100" maxlength="8" />
                            <span class="text-danger f-11">{{ errors[0] }}</span>
                        </ValidationProvider>
                        
                    </div>
                    <div class="col-11 my-2">
                        <p class="text-general pl-3 f-14">
                            Descripcíon
                        </p>
                        <el-input v-model="kit.descripcion" type="textarea" :rows="5" class="w-100" />
                    </div>
                    <div class="col-11 my-2">
                        <p class="text-general pl-3 f-14">
                            Categoría
                        </p>
                        <input-categorias ref="categorias" :multiple="true" :idscategorias="kit.categorias"></input-categorias>
                    </div>
                    <div class="col-11 my-2">
                        <p class="text-general pl-3 f-14">
                            Etiquetas
                        </p>
                        <input-etiquetas ref="etiquetas" :idsetiquetas="kit.etiquetas"></input-etiquetas> 
                    </div>
                </div>
            </ValidationObserver>
        </div>
        <div class="bg-white d-middle py-2">
            <div class="bg-light-f5 shadow px-2 py-2 br-10 text-general2 mx-2">
                <i class="icon-content-duplicate f-20 cr-pointer" @click="duplicar"/>
            </div>
            <div class="bg-light-f5 shadow px-2 py-2 br-10 text-general2 mx-2">
                <i class="icon-trash-empty f-20" />
            </div>
            <div class="col" id="loading-registro">
                <div class="bg-general text-white py-2 px-2 br-10 text-center cr-pointer" @click="guardarKit">
                    Guardar
                </div>
            </div>
        </div>
        <!-- partials -->
        <modal-duplicar ref="modalDuplicar" />
    </section>
</template>

<script>
import CedisKits from '~/services/cedis/cedis_kits'
import {mapGetters} from 'vuex'

export default {
    props:{
        kit:{
            type: Object,
            default: () => {
                return {}
            },
        }
    },
    components:{
        modalDuplicar: () => import('./partials/modalDuplicar')
    },
    data(){
        
        return {            
            slimOptions: {
                ratio: '1:1',
                label:'Subir imagen de perfil',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok'
            },
        }
    },
    computed:{
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
            id_moneda:'cedis/id_moneda',
            cedis:'cedis/cedis',
        }),
    },
    mounted(){
        this.$refs.foto_kit_registro.instanciaCrop.load(this.kit.foto)
    },
    methods:{
        async guardarKit(){            
            try {
                const valid = await this.$refs.formGuardarKit.validate()
                if(valid){
                    let loading = this.$loading(
                        {
                            lock: true,
                            target:`#loading-registro`
                        });
                    let archivo = this.$refs.foto_kit_registro.instanciaCrop.dataBase64.output
                    this.kit.foto = archivo.image
                    this.kit.id_cedis = this.$route.params.id_cedis
                    this.kit.categorias = this.$refs.categorias.getSeleccion()
                    this.kit.etiquetas = this.$refs.etiquetas.getSeleccion()

                    const { data } = await CedisKits.GuardarKit(this.kit)
                    this.notificacion('Kit Actualizado', 'Actualización Exitosa', 'success')
                    loading.close()
                }else{
                    this.notificacion('Errores', 'Error en validación de campos', 'warning')
                }
                
            } catch (error){
                this.error_catch(error)
            }
            
        },
        duplicar(){
            this.$refs.modalDuplicar.toggle(this.kit);
        },
    }
}
</script>